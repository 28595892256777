export const locale = {

    lang: 'NLNL',
  
    data: {
  
        loginButton: "Inloggen",

        userName: "Gebruikersnaam",
        
        pass: "Wachtwoord",
        
        remember: "Onthoud mij",
        
        forgotPass: "Wachtwoord vergeten",
        
        modules: "Modules",
        
        generic: "Algemeen",
        
        search: "Zoeken",
        generateLink: "koppeling genereren",
        logout: "Uitloggen",
        jobListMessageArea:"berichtgebied taaklijst",
        
        waitingJob: "Mijn wachtende taak",
        
        myStarted: "Ik ben begonnen",
        
        myIncluded: "Ik ben betrokken",
        
        myCompleted: "Voltooide taken",
        
        jobList: "Taaklijst",
        
        formNo: "Formulier Nr.",
        
        Status: "Status",
        starter: "Gestart",
        assigned: "Toegewezen",
        timeoutDate: "Timeout-datum",
        
        startDate: "Startdatum",
        
        processing: "Wordt verwerkt",
        
        you: "Jij",
        
        history: "Geschiedenis",
        
        Description: "Beschrijving",
        
        Date: "Datum",
        
        growShrink: "Groei/Verkleining",
        
        close: "Sluiten",
        
        GridReset: "Rasterinstellingen resetten",
        
        ColumnSelect: "Kolomselectie",
        
        jobName: "Taaknaam",
        
        keyword: "Trefwoord",
        
        completion: "Voltooiing",
        
        all: "alles",
        
    
        completedDate: "Voltooiingsdatum",

        completions: "Voltooid",
        ongoing: "Lopend",
        
        formName: "Formulier naam",
        
        sunday: "Zondag",
        monday: "Maandag",
        tuesday: "Dinsdag",
        wednesday: "Woensdag",
        thursday: "Donderdag",
        friday: "Vrijdag",
        saturday: "Zaterdag",
        
        pdfExport: "PDF exporteren",
        
        sendPassword: "Mijn wachtwoord versturen",
        
        assignedDate: "Toewijzingsdatum",
        
        events: "Gebeurtenissen",
        
        new: "Nieuw",
        
        Save: "Opslaan",
        
        Formula: "Formule",
        
        selectDataSource: "Gegevensbron selecteren",
        
        requireField: "Verplicht veld",
        
        Suffix: "Achtervoegsel",
        
        valueColumn: "Waardekolom",
        
        showLength: "Aantal taken weergeven in kolommen",
        
        clearButton: "Wis knop",
        
        allCaps: "Alle hoofdletters",
        
        autoAsc: "Auto oplopend",
        
        width: "Breedte",
        
        Length: "Lengte",
        
        Function: "Functies",
        
        selectElement: "Element selecteren",
        
        selectColumn: "Kolom selecteren",
        
        edit: "Bewerken",
        
        changeEventActions: "Evenementsacties wijzigen",
        
        Barcode: "Barcode",
        
        systemInfo: "Systeeminformatie",
        
    
        prefix: "Voorvoegsel",

        max: "Maximaal",
        
        showMinMaxInfo: "Toon Minimale en Maximale informatie",
        
        selectUser: "Gebruiker selecteren",
        
        spinButton: "Draaiknop",
        
        numberIncDecButton: "Knop voor verhogen/verlagen van het nummer",
        
        decimal: "Decimaal",
        
        numeral: "Cijfer",
        
        wrongFormat: "U heeft het verkeerde formaat ingevoerd",
        
        height: "Hoogte",
        
        microphone: "Microfoon",
        
        notifyDataReset: "Gegevensreset",
        
        data: "Gegevens",
        
        dynamicData: "Dynamische gegevens",
        
        manualData: "Handmatige gegevens",
        
        selectDataGrid: "Selecteer een gegevensraster",
        
        overrideUserChange: "Gebruikerswijzigingen overschrijven",
        
        textColumn: "Tekstkolom",
        
        makeInitialValue: "Maak initiële waarde",
        
        undo: "Ongedaan maken",
        
        copy: "Kopiëren",
        
        preview: "Voorbeeld",
        
        delete: "Verwijderen",
        
        cancel: "Annuleren",
        
        languageSettings: "Taalinstellingen",
        
        messageSqlLinkDeleted: "Omdat u gegevens handmatig heeft toegevoegd, worden het SQL-script en de linkadres verwijderd! Doorgaan?",

        messageSqlLinkDeletedForMenu: "Het selecteren van gegevens als een menu zal het SQL-scriptbestand en het linkadres verwijderen! Wil je doorgaan?",
        
        permissions: "Rechten",
        
        objectid: "Object-ID",
        
        fontSize: "Lettergrootte",
        
        fontWidth: "Tekstbreedte",
        
        fontStyle: "Lettertype stijl",
        
        underline: "Onderstrepen",
        
        textColor: "Tekstkleur",
        
        visibility: "Zichtbaarheid",
        
        textAlignment: "Tekstuitlijning",
        
        settings: "Instellingen",
        
        messageUnsaved: "Weet u zeker dat u wilt doorgaan zonder uw wijzigingen op te slaan?",
        
        warning: "Waarschuwing!",
        
        userAuthorizations: "Gebruikersautorisaties",
        
        formPreferences: "Formulier voorkeuren",
        
        formOpeningevent: "Formulier openen evenement",
        
        chooseConnectionAddress: "Kies verbindingsadres",
        
        selectPriorty: "Selecteer Prioriteit",
        
        loadingEditor: "Editor wordt geladen ...",
        
        showAddButton: "Knop 'Kolom toevoegen' weergeven",
        
        update: "Bijwerken",
        
        connectionID: "Verbindings-ID",
        
        formPreview: "Formuliervoorbeeld",
        
    
        nameDisplayedform: "Naam van het weergegeven formulier",

        formNamevariable: "Formulier naamvariabele",
        
        category: "Categorie",
        formColor: "Formulier kleur",
        
        generalForm: "Algemeen formulier",
        
        saveForm: "Formulier opslaan",
        
        messageNeworupdate: "De wijzigingen die u hebt aangebracht aan het bestaande formulier worden verwijderd. Doorgaan?",
        
        messageDelete: "Weet u zeker dat u het formulier wilt verwijderen?",
        
        toastDeleted: "Formulier verwijderd",
        
        toastError: "Er is helaas een fout opgetreden bij de verwerking",
        
        alertDifferentID: "Dit ID wordt al elders gebruikt. Geef een ander ID op, alstublieft.",
        
        confirmChanges: "Wijzigingen bevestigen",
        
        toastUserpermission: "Gebruiker heeft geen toestemming voor formulierontwerp.",
        
        toastCodeeditor: "De code-editor bestaat al.",
        
        messageDeletesure: "De elementen in deze component worden verwijderd.",
        
        messageSure: "Weet u het zeker?",
        
        
        sccessful: "Succesvol",
        
        error: "Fout",
        
        toastFormupdate: "Formulier bijgewerkt",
        
        toastMistake: "Er is een fout opgetreden.",
        
        toastSaved: "Opgeslagen",
        
        toastCopied: "Gekopieerd",
        
        toastEmptyFormMessage: "Er zijn nog geen formulierelementen toegevoegd.",
        
        toastGiveName: "Geef alstublieft een naam aan uw formulier.",
        
        toastGiveVariableName: "Geef alstublieft een naam op voor de formulier naamvariabele.",
        
        toastVariableNameNumberWarning: "De naam van de variabele mag niet met een cijfer beginnen.",
        
        formCopy: "Formulier kopiëren",
        
        messageButtonDelete: "Weet u zeker dat u de knop wilt verwijderen?",
        
        noDataFound: "Geen gegevens gevonden",
        
        language: "Taal",
        
        filterDatagrid: "Datagrid filteren",
        

        addManualData: "Handmatige gegevens toevoegen tijdens runtime",

        dateFormat: "Datum formaat",
        
        messageSqlLinkDelete: "Omdat u handmatig gegevens heeft toegevoegd, worden het SQL-script en de link-adres verwijderd! Doorgaan?",
        
        timeOption: "Tijdsinstelling",
        
        defaultTodaysDate: "Standaard vandaag's datum gebruiken",
        
        dateRange: "Datum bereik",
        
        minToday: "Minimaal vandaag",
        
        maxToday: "Maximaal vandaag",
        
        dateRangeDay: "Datum bereik (dag)",
        
        enterDateRangeValue: "Voer de waarde voor het datum bereik in",
        
        imageIdColumn: "ID-kolom",
        
        statusSettings: "Statusinstellingen",
        
        active: "Actief",
        
        passive: "Inactief",
        
        enable: "Inschakelen",
        
        disable: "Uitschakelen",
        
        visible: "Zichtbaar",
        
        invisible: "Onzichtbaar",
        
        items: "Items",
        
        showItems: "Items tonen",
        
        array: "Array",
        
        letLabelAppear: "Label weergeven",
        
        add: "Toevoegen",
        
        horizontal: "Horizontaal",
        
        vertical: "Verticaal",
        
        text: "Tekst",
        

        alertFillRequiredFields: "Vul alstublieft de vereiste velden in.",

        alertSignatureField: "Het handtekeningsveld mag niet leeg zijn.",
        
        messageApproveTransaction: "Akkoord met de transactie?",
        
        button: "Knop",
        
        reasonforRefusal: "Reden voor weigering",
        
        enterReasonRejection: "Voer reden voor afwijzing in",
        
        reject: "Afwijzen",
        
        addDeleteButton: "Knop toevoegen/verwijderen",
        
        showBorder: "Rand tonen",
        
        buttons: "Knoppen",
        
        clean: "Leegmaken",
        
        buttonSettings: "Knopinstellingen",
        
        saveLocation: "Locatie opslaan",
        
        paymentButton: "Betaalknop",
        
        paymentProvider: "Betaalprovider",
        
        price: "Prijs",
        
        numberOfInstallments: "Aantal termijnen",
        
        descriptionField: "Beschrijvingsveld",
        
        current: "Huidig",
        
        currentForm: "Huidig formulier",
        
        name: "Naam",
        
        surname: "Achternaam",
        
        country: "Land",
        
        city: "Stad",
        
        address: "Adres",
        
        authorizations: "Authorisaties",
        
        users: "Gebruikers",
        
        user: "Gebruiker",
        
        givePermission: "Geef toestemming",
        
        groups: "Groepen",
        
        group: "Groep",
        
        saveandSend: "Opslaan en verzenden",
        
        approve: "Goedkeuren",
        
        sendBack: "Terugsturen",
        
        deslineAndEnd: "Afkeuren en beëindigen",
        
        complete: "Voltooien",
        
        blue: "Blauw",
        
        gray: "Grijs",
        
        green: "Groen",
        
        red: "Rood",
        
        left: "Links",
        
        right: "Rechts",
        
        color: "Kleur",
        
        symbol: "Symbool",
        
        position: "Positie",
        
        action: "Actie",
        fileLink: "Bestandslink",
        givePasswordToTheLink: "geef een wachtwoord voor de link",
        giveTheLinkAnExpirationDate: "Geef de link een vervaldatum",

        total: "Totaal",
        
        filePath: "Bestandspad",
        
        fileName: "Bestandsnaam",
        
        fileUpload: "Bestand uploaden",
        
        choose: "Kiezen",
        
        updateData: "Gegevens bijwerken",
        
        
        show: "Tonen",
        

        columns: "Kolommen",

        changeEvent: "Wijzigingsevenement",
        
        columnSettings: "Kolominstellingen",
        
        columnName: "Kolomnaam",
        
        deleteColumn: "Kolom verwijderen",
        
        columnSqlScripts: "SQL-scripts voor kolom",
        
        imageNameColumn: "Bestandsnaamkolom",
        
        columnLanguageSetting: "Taalinstelling voor kolom",
        
        dataSource: "Gegevensbron",
        
        filter: "Filter",
        
        reset: "Resetten",
        
        column: "Kolom",
        
        image: "Afbeelding",
        
        searchLocation: "Zoeklocatie...",
        
        draggable: "Slepen mogelijk",
        
        usersCanChange: "Gebruikers kunnen wijzigen",
        
        multiMarker: "Meerdere markeringen",
        
        markerLimit: "Markeringlimiet (0: geen limiet)",
        
        meetingType: "Vergadertype",
        
        meetingName: "Vergaderingsnaam",
        
        
        loadingFiles: "Bestanden worden geladen...",
        
        fileSizeExceeded: "Bestandsgrootte overschreden",
        
        filesLoaded: "Bestanden geladen",
        
        condition: "Voorwaarde",
        
        value: "Waarde",
        
        connectionAddress: "Verbindingsadres",
        
        imageTypeColumn: "Bestandstype kolom",
        
        visibleColumn: "Zichtbare kolom",
        
        selectionMode: "Selectiemodus",
        
        selectMode: "Selectiemodus",
        
        datagridFiltering: "Datagrid filteren",
        
        imageDataColumn: "Bestandskolom",
        
        
        selectDataColumn: "Selecteer een kolom uit het datagrid",
        
        dataFormat: "Gegevensformaat",
        
        
        photoPreview: "Fotovoorbeeld",
        
        videoPreview: "Video-voorbeeld",
        
        displayPhoto: "Foto weergeven",
        
        displayVideo: "Video weergeven",
        
        imageColumn: "Afbeeldingskolom",
        
        videoColumn: "Videokolom",
        
        
        columnNameAppear: "Kolomnaam om te tonen",
        
        
        selected: "Geselecteerd",
        
        inStock: "Op voorraad",
        
        messageSave: "Wilt u uw wijzigingen opslaan?",
        
        gallery: "Galerij",
        
        dollar: "Dollar",
        
        sterling: "Brits pond",

        underPhoto: "Onder de foto",
        
        topPhoto: "Bovenste foto",
        
        
        byCategory: "Op categorie",
        
        dashboardShowHide: "Dashboard weergeven/verbergen",
        
        showDetail: "Details weergeven",
        
        hideDetail: "Details verbergen",
        
        decrease: "Verminderen",
        
        increase: "Verhogen",
        
        watch: "Bekijken",
        
        watchMovie: "Film bekijken",
        
        noRecordsFound: "Geen records gevonden!",
        
        appearance: "Uiterlijk",
        
        numberofColumns: "Aantal kolommen",
        
        pagingRecords: "Paginering records",
        
        detailLocation: "Detaillocatie",
        
        hideImages: "Afbeeldingen verbergen",
        
        quantityCategoryDisable: "Categorie uitschakelen bij verhoging van de hoeveelheid",
        
        general: "Algemeen",
        
        idColumn: "ID-kolom",
        
        availableQuantity: "Beschikbare hoeveelheid",
        
        categoryColumn: "Categoriekolom",
        
        photoColumn: "Fotokolom",
        
        titleColumn: "Titelkolom",
        
        priceColumn: "Prijskolom",
        
        updatablePrice: "Bij te werken prijs",
        
        videoGallery: "Video Galerij",
        
        uniqueComponent: "Uniek component",
        
        showVariant: "Variant tonen",
        
        transferTotalAmount: "Totaalbedrag overdragen",
        
        title: "Titel",
        
        hide: "Verbergen",
        
        detail: "Detail",
        
        priceQuery: "Prijsquery",
        
        quantityQuery: "Hoeveelheidsquery",
        
        
        uniqueComponentError: "Fout in uniek component",
        
        joinColumn: "Join-kolom",
        
        nameColumn: "Naamkolom",
        
        variantScript: "Variant-script",
        
        variantImages: "Variant-afbeeldingen",
        
        variantJoinColumn: "Variant-Join-kolom",
        
        showAll: "Alles weergeven",
        
        showSelected: "Geselecteerde weergeven",
        
        showByCategory: "Op categorie tonen",
        
        showInStock: "Op voorraad tonen",
        
        notYetAdded: "Nog niet toegevoegd",
        
        added: "Toegevoegd",
        
        startingDate: "Startdatum",
        
        endDate: "Einddatum",
        
        themeFeatures: "Thema-functies",
        
        menu: "Menu",
        
        selectMenu: "Menu selecteren",
        
        selectSubmenu: "Submenu selecteren",
        
        selectMenuBrowser: "Menu in browser selecteren",
        
        nameElement: "Naam element",
        
        subject: "Onderwerp",
        
        picture: "Afbeelding",
        
        pictureElement: "Afbeeldingselement",
        
        startingDateElement: "Startdatumselement",
        
        endDateElement: "Einddatumselement",
        
        usersCanEdit: "Gebruikers kunnen bewerken",
        
        thisPropertyCant: "Deze eigenschap KAN NIET worden gebruikt wanneer gegevens uit SQL worden opgehaald",
        
        calendarDisplay: "Kalenderweergave eigenschappen",
        
        monthly: "Maandelijks",

        generalDisplayLunarDays: "Algemene weergave van maanstanden",
        
        agenda: "Agenda",
        
        listofWeeklyEvents: "Lijst van wekelijkse evenementen",
        
        weekly: "Wekelijks",
        
        weekend: "Weekend",
        
        generalDisplayWeekDays: "Algemene weergave van weekdagen",
        
        showWeekendDaysWhileWeekdays: "Weekenddagen tonen tijdens wekdagen tonen",
        
        daily: "Dagelijks",
        
        listofDailyEvents: "Lijst van dagelijkse evenementen",
        
        startTime: "Starttijd",
        
        endTime: "Eindtijd",
        
        changeDefaultColor: "Standaardkleuren wijzigen",
        
        weekdayColor: "Kleur weekdagen",
        
        weekendColor: "Kleur weekend",
        
        eventColor: "Kleur evenement",
        
        groupBySql: "Groeperen op basis van SQL",
        
        groupColumnName: "Naam van groepskolom",
        
        assignedColumnName: "Naam van toegewezen kolom",
        
        taskPriortiyColumnName: "Naam van kolom voor taakprioriteit",
        
        taskSubjectColumnName: "Naam van kolom voor taakonderwerp",
        
        taskDetailColumnName: "Naam van kolom voor taakdetails",
        
        taskQuery: "Taakquery",
        
        taskKeyExpression: "Uitdrukking voor taaksleutel",
        
        taskTitleExpression: "Uitdrukking voor taaktitel",
        
        taskStartDateExpression: "Uitdrukking voor taakstartdatum",
        
        taskEndDateName: "Naam van taakeinddatum",
        
        taskParentExpression: "Uitdrukking voor ouder-taak",
        
        taskProgressExpression: "Uitdrukking voor taakvoortgang",
        
        taskColorExpression: "Uitdrukking voor taakkleur",
        
        taskConnections: "Taakverbindingen",
        
        diagramClear: "Weet u zeker dat u het diagram wilt wissen? Deze actie kan niet ongedaan worden gemaakt.",
        uniqueComponentMenuCloneWarning: "Wijzig alstublieft het unieke component voordat u het kopieert.",
        
        filesLoading: "Bestanden worden geladen...",
        
        couldnotbeLoaded: "kon niet worden geladen.",
        
        fileSizeLimit: "Limiet bestandsgrootte",
        
        filesUploaded: "Bestanden geüpload",
        
        filesWillDeleted: "De bestanden worden verwijderd!",
        
        filesSureDelete: "Weet u zeker dat u de bestanden wilt verwijderen?",
        
        fileDelete: "Weet u zeker dat u het bestand wilt verwijderen?",
        
        uploadedPhotos: "Geüploade foto's",
        
        maxSize: "Maximale grootte",
        
        center: "Midden",
        
        size: "Grootte",
        
        canItBeUpdated: "kan het worden bijgewerkt na installatie?",
        
        uploadedFiles: "Geüploade bestanden",
        
        type: "Type",
        
        showdeleteOldData: "Opties voor oude gegevens verwijderen weergeven",
        
        videoBrowser: "Uw browser ondersteunt de video-tag niet.",
        
        deleted: "Verwijderd",
        
        theseComponentElementsDeleted: "Deze componentelementen worden verwijderd.",
        
        invalidTarget: "Ongeldig doel",
        
        ridge: "Rechte lijn",
        
        dotted: "Gestippeld",
        
        dashed: "Gestreept",
        
        addRowColumn: "Rij/kolom toevoegen",
        
        dataPagingShow: "Data als pagina's ophalen",

        borderType: "Randtype",
        
        maxCharacters: "Maximaal aantal tekens",
        
        edgeThickness: "Randdikte",
        
        lineSpacing: "Regelafstand",
        
        settingsRow: "Rijinstellingen",
        
        addRow: "Rij toevoegen",
        
        deleteRow: "Rij verwijderen",
        
        rowPermission: "Rijtoestemming",
        
        addTabs: "Tabbladen toevoegen",
        
        deletePanel: "Paneel verwijderen",
        
        tabPermission: "Tabbladtoestemming",
        
        addPanel: "Paneel toevoegen",
        
        panelPermission: "Paneeltoestemmingen",
        
        backgroundColor: "Achtergrondkleur",
        
        saveAndCloseChildForm: "Dit onderliggende formulier opslaan en sluiten terwijl een nieuw formulier wordt geopend",
        
        icon: "Pictogram",
        
        selectForm: "Formulier selecteren",
        
        fontColor: "Tekstkleur",
        
        minCharacters: "Minimaal aantal tekens",
        
        generalCategory: "Algemene categorie",
        
        removeRow: "Weet u zeker dat u de rij wilt verwijderen?",
        
        send: "Verzenden",
        
        declineFinish: "Weigeren en afsluiten",
        
        finish: "Afsluiten",
        
        noData: "Geen gegevens",
        
        createEditMenu: "Menu maken/bewerken",
        
        addNewBreakdown: "Nieuwe uitsplitsing toevoegen",
        
        addForm: "Formulier toevoegen",
        
        addBottomBreak: "Onderste scheiding toevoegen",
        
        addNode: "Knooppunt toevoegen",
        
        topCategory: "Topcategorie",
        
        explanation: "Uitleg",
        
        browserTitle: "Browser-titel",
        
        formTitle: "Formuliertitel",
        
        addSqlColumn: "SQL-kolom toevoegen",
        
        formBg: "Formulierachtergrond",
        
        codeEditor: "Code-editor",
        
        authorityType: "Autoriteitstype",
        
        viewOnly: "Alleen bekijken",
        
        dark: "Donker",
        
        white: "Wit",
        
        hightBlack: "Zwart",
        
        run: "Uitvoeren",
        
        rowColor: "Rijkleur",
        
        operator: "Operator",
        
        flowOfForm: "Formulierstroom",
        
        columnWidth: "Kolombreedte",
        
        alignLeft: "Links uitlijnen",
        
        alignRight: "Rechts uitlijnen",
        
        alignButton: "Knop uitlijnen",
        
        imageTableNameColumn: "Tabelnaam",
        
        uploadBg: "Achtergrond uploaden",
        
        selectFile: "Bestand selecteren",
        
        dropFile: "Sleep bestand hier",
        
        alwaysRoundUp: "Altijd naar boven afronden",
        
        alwaysRoundBot: "Altijd naar beneden afronden",
        
        default: "Standaard",
        
        deleteOldDataTable: "Oude gegevens van de tabel verwijderen?",
        
        deleteOldData: "Oude gegevens verwijderen?",
        
        integer: "Geheel getal",
        
        double: "Decimaal getal",
        

        pleaseFillFields: "Vul alstublieft de vereiste velden in.",

        toastColumnNameNumber: "De kolomnaam mag niet beginnen met een getal.",
        
        areYouSure: "Weet u het zeker?",
        
        columnDeleted: "De kolom wordt verwijderd.",
        
        allColumnDeleted: "Alle kolommen worden verwijderd.",
        
        idCantStartNumber: "Het ID mag niet beginnen met een getal.",
        
        uploadFile: "Bestand uploaden",
        
        fileViewer: "Bestandsviewer",
        
        ok: "OK",
        
        map: "Kaart",
        
        editPanel: "Paneel bewerken",
        
        filterColumnName: "Kolomnaam filteren",
        
        hintExamples: "TotalAmount, Voorbeeld: Salaris",
        
        
        columnLanguageSettings: "Kolom taalinstellingen",
        
       
        
        fileColumn: "Bestandskolom",
        
        
        fileNameColumn: "Bestandsnaamkolom",
        
        extensionColumn: "Extensiekolom",
        
        multipleSelection: "Meervoudige selectie",
        
        addSelectionsRows: "Selecties als rijen toevoegen",
        
        mapSettings: "Kaartinstellingen",
        
        
        formatAndFeatures: "Opmaak en functies",
        
        enterDateRange: "Voer de waarde van het datumbereik in",
        
        filesCanUploadedUsers: "Gebruikers kunnen bestanden uploaden",
        
        barcodeColumn: "Barcodescanner kolom",
        
        autoAscend: "Automatisch oplopend",
        
        roundType: "Afrondingstype",
        
        font: "Lettertype",
        
        columnProcess: "Kolombewerking",
        
        exportColumnTotal: "Totaal van kolom exporteren",
        
        outsideElementDisabled: "Externe elementen uitschakelen",
        
        chat: "Chat",
        
        languages: "Talen",
        
        display: "Weergave",
        
        enterYourComment: "Voer uw opmerking in",
        
        comment: "Opmerking",
        
        fileUploaded: "Bestanden geüpload",
        
        pdfViewIsButton: "Weergeven als knop",
        
        takeScreenshot: "Maak een schermafbeelding",
        
        alertText: "Waarschuwingstekst",
        
        clearButtonVisible: "Zichtbaarheid van de wissen-knop",
        
        allText: "Alles",
        
        letter: "Alleen letters",
        
        letterandnumber: "Letters en cijfers",
        
        letterandsymbol: "Letters en symbolen",
        
        numberandsymbol: "Cijfers en symbolen",
        
        showIDColumn: "ID-kolom tonen",
        
        showCopyButton: "Kopieerknop tonen",
        
        showDeleteButton: "Verwijderknop tonen",
        
        showCheckboxButton: "Selectievakje knop tonen",
        
        autoRowHeight: "Automatische rijhoogte",
        
        exportImport: "Formulier Import/Export",
        
        export: "Exporteren",
        
        import: "Importeren",
        
        toastImport: "Formulierimport voltooid.",
        
        warningApproveImportReport: "Bevestig de operatie na de vereiste aanpassingen. Het importeren van het rapport sluit het momenteel geopende rapport af.",
        reportName: "Naam van rapport",
        emptyReportName: "Voeg de rapportnaam toe vanaf de rechterkant.",
        
        old: "Oud",
        
        
        upperNode: "Bovenste knooppunt",
        

        pdfViewStaticPdf: "Statisch bestand weergeven",

        actions: "Acties",
        
        loading: "Bezig met laden",
        
        exportDataToExcell: "Alle gegevens exporteren naar Excel",
        
        showRefreshIcon: "Vernieuwingspictogram tonen",
        
        headerFilter: "Koptekstfilter",
        
        filterRowVisible: "Zoekveld in kolom weergeven",
        
        password: "Wachtwoord",
        
        categories: "Categorieën",
        
        pressEnter: "Druk op Enter",
        
        historyColumnValue: "Geschiedenis kolomwaarde",
        
        hideMobile: "Verbergen op mobiel",
        
        align: "Uitlijnen",
        
       
        
        theme: "Thema",
        
        info: "Informatie",
        
        showTitle: "Titel tonen",
        
        purple: "Paars",
        
        brown: "Bruin",
        
        yellow: "Geel",
        
        turnOnComments: "Reacties inschakelen",
        
        showContent: "Inhoud tonen",
        
        canBeHidden: "Kan worden verborgen",
        
        allowEdit: "Bewerken toestaan",
        
        allowDelete: "Verwijderen toestaan",

        allowAdd: "Toevoegen toestaan",

        allowCopy: "Kopiëren toestaan",
        
        toastDeleteErrorMessage: "Dit item wordt gebruikt in formulier voorkeur scripts. Het kan niet worden verwijderd.",
        
        formOpeningMultiElementEvent: "Gebeurtenis voor het openen van formulier van meerdere elementen",
        
        resetDatagridLocalSettings: "Datagrid taalinstellingen resetten",
          
        loginP1: "River biedt een holistisch geheugensysteem als het geheugen van uw bedrijf, dat informatie bevat zoals eerdere ervaringen, successen, mislukkingen, lessen, cultuur en waarden van uw organisatie of instelling, die kunnen dienen als referentiebron voor toekomstige beslissingen en acties. Processen, de kennis van uw medewerkers, klantenervaringen, de ontwikkeling van producten en diensten en vele andere informatie. Deze informatie zal de toekomstige doelen en strategieën van het bedrijf sturen.",
          
        loginP2: "River helpt uw bedrijf om bedrijfsprocessen efficiënter en effectiever uit te voeren, dankzij de web- en geavanceerde mobiele applicatiestructuur. Alles wat u nodig heeft, is zo dichtbij als uw computer, tablet of smartphone.",
          
        loginConnect: "Verbinden",
          
        loginText: "Inloggen",
          
        loginRightMore: "Meer",
          
        loginHeaderP1: "Uw bedrijfsgeheugen",
          
        loginHeaderP2: "Mobiliteit",
          
        loginHeaderP3: "Grafische prestaties",
          
        kanbanaddTasks: "Kanban taak toevoegen",
          
        tasksstatusDesciption: "Dit toont de naam van de kolom waar de Kanban-taak aan is toegewezen.",
          
        taskssubjectDescription: "Stelt u in staat om de titel van de Kanban-taak in te voeren. De Kanban-titel is een verplicht veld.",
          
        taskassignedToTask: "Toewijzing van groepen aan taak",
          
        taskassignedTaskDescription: "Hiermee kunt u groepen selecteren om aan de Kanban-taak te worden toegewezen.",
          
        addedForTasks: "Toegevoegd voor taak",
          
        taskAssignedInfoDescription: "Personen die op de hoogte moeten worden gesteld over de taak",
          
          
        assignedInfo: "Personen die geïnformeerd moeten worden",
          
        groupInfo: "Groepen die geïnformeerd moeten worden over de taak",
          
        sortEnd: "Sorteren op einddatum",
          
        sender: "Afzender",
          
        forward: "Doorsturen",
          
        attachments: "Bijlagen",
          
        loginP3: "Kanban- en Gantt-diagrammen zijn uiterst nuttige hulpmiddelen om een project of werkstroom te visualiseren. Ze bieden een visuele weergave van werkitems, hun huidige status en de workflow in verschillende fasen van het bedrijf. Dit vergemakkelijkt het identificeren van knelpunten, het prioriteren van taken en het verbeteren van de operationele efficiëntie. Kanban- en Gantt-diagrammen zijn praktisch en effectief voor het beheer van agile projecten, softwareontwikkeling en andere continue verbeteringsprocessen.",
          
        taskdetailDescription: "Hiermee kunt u details invoeren voor de Kanban-taak. Kanban-detail is een verplicht veld.",
          
        taskassignedDescription: "Hiermee kunt u gebruikers toewijzen aan de Kanban-taak. Er moet minimaal één gebruiker aan Kanban worden toegewezen.",
          
        taskstartdateDescription: "Hiermee kunt u de startdatum van de Kanban-taak selecteren. In Kanban wordt de startdatum automatisch overgenomen uit het tijdstip van aanmaak van de taak.",
          
        taskenddateDescription: "Hiermee kunt u de einddatum van de Kanban-taak selecteren. In Kanban ligt de einddatum van de taak automatisch een week na het tijdstip van aanmaak van de taak.",
          
        taskpriDescription: "Hiermee kunt u de prioriteitsstatus van de Kanban-taak selecteren. In Kanban wordt de taakdeadline automatisch een week na het tijdstip van aanmaak van de taak ingesteld.",
          
        tagsDescription: "Hiermee kunt u tags toevoegen aan de Kanban-taak. Tags worden toegevoegd gescheiden door komma's.",
          
        outlookDescription: "Als de optie om de taak aan Outlook toe te voegen is ingeschakeld, wordt de taak automatisch in de kalender ingevoerd wanneer u zich aanmeldt bij uw Outlook-account.",
          
        taskAssInfoDescription: "Dit is het veld voor personen die niet zijn toegewezen aan de taak, maar van wie informatie wordt gevraagd. Personen die worden geïnformeerd, kunnen geen acties ondernemen met betrekking tot taken.",
          
        receiver: "Ontvanger",
          
        draft: "Concept",
          
        spam: "Spam",
          
        filterstate: "Filteren op status",
          
        checkForspelling: "Spelfouten controleren",
          
        emailToForward: "E-mail om door te sturen",
          
        lowImpMails: "Minder belangrijke e-mails",
          
        normalImpMails: "Normaal belangrijke e-mails",
          
        highImpEmails: "Hoogst belangrijke e-mails",
          
        showReadMails: "Gelezen e-mails weergeven",
          
        groupinfoDescription: "Dit is het veld voor groepen die niet zijn toegewezen aan een taak, maar van wie informatie wordt gevraagd. Personen die tot de geïnformeerde groepen behoren, kunnen geen acties ondernemen met betrekking tot taken.",
          
        timeoutMission: "Tijd tot waarschuwing voor taakeinde",
          
        timeoutmissionDescription: "Wordt gebruikt om de tijd in te stellen waarop de waarschuwing wordt gestart wanneer de einddatum van de Kanban-taak nadert.",
          
        taskperiodDescription: "Wordt gebruikt om de periodieke herinneringstijd in te stellen wanneer de einddatum van een Kanban-taak nadert.",
          
        taskProgress: "Taakvoortgang",
          
        taskProgressDescription: "Wordt gebruikt om de voortgang van een Kanban-taak weer te geven. De voortgang kan worden weergegeven in het Gantt-diagram.",
          
        updatekanbanTask: "Kanban-taak bijwerken",
          
        taskcreatorDescription: "Kanban laat de taakmaker zien.",
          
          
        uploadfileDescription: "Wordt gebruikt om bestanden naar de Kanban-taak te uploaden. Bestanden of afbeeldingen worden opgeslagen in het DMS.",
          
        infoPersons: "Personen die moeten worden geïnformeerd",
          
        warningtime: "Waarschuwingstijd",
          
        taskPeriod: "Taakperiode",
          
        taskProgresSatus: "Taakvoortgang",
          
        makeTask: "Taak aanmaken",
          
        addedforTasks: "Toegevoegd voor taak",
          
        outlookIntegration: "Outlook-integratie",
          
        loginoutlook: "Inloggen bij Outlook-kalender",
          
        fetchoutlook: "Gegevens ophalen uit Outlook-kalender",
          
        progressStatus: "Voortgangsstatus",
          
        taskColors: "Taakkleuren",
          
        addcalendarTask: "Taak aan kalender toevoegen",
          
          
        updatetaskCalendar: "Kalendertaak bijwerken",
          
        statusComplete: "Filteren op voltooiingsstatus",
          
        priorityFilter: "Prioriteitsfilter",
          
        columnFilter: "Kolomfilter",
          
        assignPerson: "Toegewezen personen",
          
        assignGroup: "Toegewezen groepen",
          
        noTask: "Geen taken gevonden",
          
        resetSort: "Sortering resetten",
          
        sortASC: "Sorteren A - Z",
          
        sortDESC: "Sorteren Z - A",
          
        sortAppointment: "Sorteren op toewijzingsdatum",
          
        trash: "Prullenbak",
          
        showOlderMail: "Oudere e-mails tonen",
          
        mailNotFound: "E-mail niet gevonden",
          
        sendNewMail: "Nieuwe e-mail verzenden",
          
        showUnreadMails: "Ongelezen e-mails tonen",
          
        updateColumnMail: "Bericht sturen naar contactpersoon wanneer taak aan kolom wordt toegevoegd",
          
        addTaskToOutlook: "Taak aan Outlook toevoegen",
          
        enterYourMessage: "Voer uw bericht in",
          
        reminder: "Herinnering",
          
        determinedToTask: "Voor taak vastgestelde tijd",
          
        remainingTime: "Resterende tijd",
          
        hours: "Uren",
          
        withoutPriority: "Geen prioriteit",
        
        lowPri: "Lage prioriteit",
        normalPri: "Normale prioriteit",
        highPri: "Hoge prioriteit",
        showAllTasks: "Alles tonen",
        showIncompleteTasks: "Onvolledige tonen",
        showCompeletedTasks: "Voltooide tonen",
        reportDesigner: "Rapport Ontwerper",
        fileManagementSystem: "Bestandsbeheersysteem",
        formDesigner: "Formulier Ontwerper",
        boardDesigner: "Bord Ontwerper",
        themeSettings: "Thema Instellingen",
        social: "Sociaal",
        profile: "Profiel",
        contacts: "Contacten",
        files: "Bestanden",
        pages: "Pagina's",
        noResultsFound: "Geen resultaten gevonden",
        processes: "Processen",
        filterByStatus: "Filteren op status",
        filterByPri: "Filteren op prioriteit",
        filterByCol: "Filteren op kolom",
        todoAddTasks: "Taken toevoegen aan Todo",
        addLabel: "Label invoeren",
        showWithSubTasks: "Tonen met subtaken",
        riverBoard: "River Boards",
        notify: "Meldingen",
        likedYourPost: "Vond je bericht leuk",
        likedYourComment: "Vond je reactie leuk",
        systemNotify: "Systeemmeldingen",
        readAllNotify: "Alle meldingen lezen",
        recommendedUsers: "Aanbevolen gebruikers",
        suggestedPosts: "Voorgestelde berichten",
        sPostReposted: "Bericht opnieuw geplaatst",
        shareSomething: "Iets delen",
        updatePost: "Bericht bijwerken",
        ifYouDoNotSelectGroup: "Als je geen groep selecteert, kan iedereen je bericht zien",
        addComment: "Reactie toevoegen",
        sendComment: "Reactie verzenden",
        areYouSureYouWantToDeleteThisPost: "Weet je zeker dat je dit bericht wilt verwijderen?",
        yes: "Ja",
        no: "Nee",
        deletePost: "Bericht verwijderen",
        postSuccesfullyDeleted: "Bericht succesvol verwijderd",
        deleteComment: "Reactie verwijderen",
        areYouSureYouWantToDeleteThisComment: "Weet je zeker dat je deze reactie wilt verwijderen?",
        commentSuccesfullyDeleted: "Reactie succesvol verwijderd",
        sharePost: "Bericht delen",
        areYouSureYouWantToShareThisPost: "Weet je zeker dat je dit bericht wilt delen?",
        repostedSuccessfully: "Succesvol opnieuw geplaatst",
        upload: "Uploaden",
        refresh: "Vernieuwen",
        allowedExtentions: "Toegestane extensies: JPG, GIF of PNG. Maximale bestandsgrootte",
        systemLandingPagePreference: "Voorkeur voor startscherm van het systeem",
        allownonadminuserstosearchforotherusers: "Niet-admin gebruikers toestaan om naar andere gebruikers te zoeken",
        passwordChange: "Wachtwoord wijzigen",
        newPassword: "Nieuw wachtwoord",
        retypethenewpassword: "Nieuw wachtwoord opnieuw invoeren",
        notifications: "Meldingen",
        showWhoLikesYourPost: "Weergeven wie je bericht leuk vindt",
        showWhoLikesYourComment: "Weergeven wie je reactie leuk vindt",
        aboutMe: "Over mij",
        bio: "Biografie",
        dateOfBirth: "Geboortedatum",
        website: "Website",
        phone: "Telefoon",
        saveChanges: "Wijzigingen opslaan",
        username: "Gebruikersnaam",
        content: "Inhoud",
        projectManagement: "Projectbeheer",
        addPage: "Pagina toevoegen",
        addFolder: "Map toevoegen",
        showUsers: "Gebruikers die kunnen zien",
        showGroups: "Groepen die kunnen zien",
        
        readonlyUsers: "Gebruikers die kunnen lezen",
        readonlyGroups: "Groepen die kunnen lezen",
        columnHeight: "Kolomhoogte",
        showKanbanCount: "Toon Kanban-aantallen",
        kanbanSettings: "Kanban-instellingen",
        gantSettings: "Gantt-instellingen",
        schedulerSettings: "Agenda-instellingen",
        showRowLines: "Toon rijlijnen",
        activateOutlookConnection: "Outlook-verbinding activeren",
        selectOutlookColumn: "Selecteer Outlook-kolom",
        selectSMTPopoverText: "Selecteer de SMTP-mail die moet worden gebruikt in Kanban, Gantt, Kalender of Takencomponenten.",
        menuFilePathId: "ID van River-menu map",
        recycleBinPathName: "Naam van de River Prullenbak-map",
        recycleBinPathId: "ID van de River Prullenbak-map",
        fileNameChangedMessage: "Bestandsnaam succesvol gewijzigd.",
        fileNameChangedErrorMessage: "Kan bestandsnaam niet wijzigen.",
        fileCreatedMessage: "Bestand succesvol aangemaakt.",
        newTaskMailDetailPopoverText: "Bij toewijzing van nieuwe taken aan Kanban, Gantt, Kalender of Takencomponenten, wordt het gedeelte van de e-mailinhoud geselecteerd dat automatisch aan de toegewezen gebruikers wordt verzonden.",
        taskOnDropMailPopoverText: "Het gedeelte van de e-mailinhoud wordt geselecteerd dat automatisch aan Kanban, Gantt, Kalender of Takencomponenten wordt toegevoegd wanneer de kolom wordt gewijzigd met een taak.",
        createdBy: "Gemaakt door",
        updatedBy: "Bijgewerkt door",
        createdDate: "Gemaakt op",
        updatedDate: "Bijgewerkt op",
        folderPermissionSettings: "Machtigingsinstellingen voor mappen",
        quantity: "Aantal",
        fileManagementPermissionSettings: "Machtigingsinstellingen voor bestandsbeheer",
        fileNotFoundErrorText: "Geen bestanden gevonden die aan de zoekcriteria voldoen.",
        updateTaskMailDetailPopoverText: "Het gedeelte van de e-mailinhoud wordt geselecteerd dat automatisch aan de toegewezen gebruikers wordt verzonden wanneer er wijzigingen zijn in de relevante taken in Kanban, Gantt, Kalender of Takencomponenten.",
        selectOutlookColumnPopoverText: "Selecteer de kolom om de taken die via Outlook worden toegevoegd te bevatten.",
        createFolder: "Map aanmaken",
        versionHistory: "Versiegeschiedenis",
        deletePermanently: "Definitief verwijderen",
        recover: "Herstellen",
        groupPermissions: "Groepsmachtigingen",
        allowUpload: "Bestandsupload toestaan",
        allowView: "Bestandsweergave toestaan",
        allowDownload: "Bestandsdownload toestaan",
        permissionType: "Machtigingstype",
        applyToSubFolders: "Toepassen op submappen",
        newFolderName: "Nieuwe mapnaam",
        tagTips: "Tags moeten voorafgaan met een #-teken. Ze mogen alleen letters en cijfers bevatten.",
        addAsNewVersion: "Toevoegen als nieuwe versie",
        addAndChangeName: "Uploaden met naam wijzigen",
        skip: "Overslaan",
        fileExistError: "Bestand met dezelfde naam bestaat al in de doelmap",
        processFileShow: "Toon River Directory Map",
        processFilePathName: "Naam van de River Directory Map",
        processFilePathId: "ID van de River Directory Map",
        menuFileShow: "Toon River Menu Map",
        menuFilePathName: "Naam van de River Menu Map",
        fileCreatedErrorMessage: "Bestand maken is mislukt.",
        fileDeletedMessage: "Bestand verwijderd",
        fileDeletedErrorMessage: "Het verwijderen van het bestand is mislukt.",
        fileMovedMessage: "Bestand verplaatst",
        fileMovedErrorMessage: "Het verplaatsen van het bestand is mislukt.",
        fileCopyMessage: "Bestand gekopieerd",
        fileCopyErrorMessage: "Het kopiëren van het bestand is mislukt.",
        fileDownloadMessage: "Bestand gedownload",
        fileDownloadErrorMessage: "Het downloaden van het bestand is mislukt.",
        fileSizeShowErrorMessage: "Vanwege de grote bestandsgrootte kan de inhoud niet worden weergegeven. Download alstublieft het bestand.",
        unsupportedFile: "Niet-ondersteund bestand",
        fileNameError: "Er bestaat al een bestand met deze naam. Probeer het opnieuw met een andere naam.",
        fileGivePermissionMessage: "Bestandsmachtiging succesvol verleend.",
        fileGivePermissionErrorMessage: "Fout bij het verlenen van bestandsmachtiging. Registratie is mislukt.",
        groupName: "Groepsnaam",
        createGroup: "Groep aanmaken",
        conversation: "Gesprekken",
        startConversation: "Start een gesprek",
        userNotFound: "Contactpersoon niet gevonden",
        chatNotFound: "Chat niet gevonden",
        themeTypeLight: "Licht",
        themeTypeBorderedLight: "Licht met rand",
        themeHalfDark: "Halfdonker",
        themeDark: "Donker",
        themeRouteAnimation: "Pagina-overgangsanimatie",
        themeRouteAnimationFadeInLeft: "Inkomend van links vervagen",
        themeRouteAnimationZoomIn: "Inzoomen",
        themeRouteAnimationFadeIn: "Inkomend vervagen",
        themeRouteAnimationNone: "Geen",
        themeMenuType: "Menutype",
        themeMenuTypeHorizontal: "Horizontaal",
        themeMenuTypeVertical: "Verticaal",
        themeMenuCollapse: "Inklapbaar menu",
        themeNavbarColor: "Kleur van de navigatiebalk",
        themeNavbarType: "Type navigatiebalk",
        themeSticky: "Vastzetten",
        themeStatic: "Statisch",
        themeFloating: "Zwevend",
        themeHidden: "Verborgen",
        themeFooterType: "Voetteksttype",
        catalog: "Catalogus",
        riverBoards: "River Boards",
       
        clipboardDesigner: "Klembordontwerper",
        designMode: "U bevindt zich in de ontwerpmodus",
        editPage: "Pagina bewerken",
        rename: "Naam wijzigen",
        exportToPdf: "Exporteren naar PDF",
        exportToExcel: "Exporteren naar Excel",
        revenueReport: "Omzetrapport",
        header: "Koptekst",
        connection: "Verbinding",
        primaryKey: "Primaire sleutel",
        secondaryKey: "Secundaire sleutel",
        completedKey: "Voltooide sleutel",
        countKey: "Telsleutel",
        statusKey: "Statussleutel",
        progressConnection: "Voortgangsverbinding",
        progressScript: "Voortgangsscript",
        progressPrimaryKey: "Primaire sleutel van voortgang",
        progressCountKey: "Telsleutel van voortgang",
        progressPercentageKey: "Percentage sleutel van voortgang",
        progressColorKey: "Kleursleutel van voortgang",
        keyColumn: "Sleutelkolom",
        currency: "Valuta",
        whitePage: "Witte pagina",
        noReportSelected: "Geen rapport geselecteerd",
        noTypeSelected: "Geen rapporttype geselecteerd",
        whitePageReports: "Rapporten op witte pagina",
        addBranch: "Tak/uitwerking toevoegen",
        branchName: "Naam van de uitwerking",
        rowNumber: "Rijnummer",
        reportTypeSelect: "Rapporttype selecteren",
        reportDescription: "Rapportbeschrijving...",
        useWeb: "Op de website gebruiken",
        useMobile: "Op mobiele apparaten gebruiken",
        mainScript: "Hoofdscript",
        prerunScript: "Vooraf uitvoeren?",
        preScript: "Voor-script",
        testIt: "Testen",
        selectAll: "Alles selecteren",
        addReport: "Rapport toevoegen",
        selectReportType: "Rapporttype selecteren",
        reportType: "Rapporttype",
        reportDesingPermission: "De gebruiker heeft geen toestemming om rapporten te ontwerpen.",
        reportImportedOpenLeftMenu: "Rapport geïmporteerd. U kunt het openen in het linkermenu.",
        forReportExport: "Om een rapport te exporteren, moet u eerst een rapport selecteren.",
        anErrorOccurred: "Er is een fout opgetreden.",
        reportDeleted: "Rapport verwijderd",
        branchDeleted: "Uitwerking verwijderd",
        
addBranchName: "Bitte geben Sie den Namen des Breakdowns ein",
addedBranch: "Uitwerking toegevoegd",
selectConnectionAddress: "Selecteer een verbindingsadres",
enterSqlScript: "Voer het SQL-script in",
enterReportName: "Voer de rapportnaam in",
reportAdded: "Rapport toegevoegd",
numberOfDataToBeProcessed: "Verbinding succesvol. Aantal te verwerken gegevens:",
dataNotFoundCheckYourConnection: "Gegevens niet gevonden! Controleer uw verbinding en SQL-opdracht",
connectionNotFoundCheckYourConnection: "Verbinding niet gevonden! Controleer uw verbinding en SQL-opdracht",
fillConnectionAndSql: "Vul uw verbinding en SQL-opdracht in",
allTypesActivate: "Alle typen zijn geactiveerd tijdens weergave",
passwordRequired: "Wachtwoord vereist",
showLess: "Minder weergeven",
showMore: "Meer weergeven",
authority: "Autoriteit",
preScriptDescription: "Beschrijving van het voorafgaande script",
enterReportFilters: "Voer rapportfilters in",
gridDesigner: "Rasterontwerper",
addMainBranch: "Hoofduitwerking toevoegen",
resetSettings: "Instellingen resetten",
columsToGroup: "Gebruik het contextmenu van de kolomkoppen om gegevens te groeperen",
customize: "Aanpassen",
colorType: "Kleurtype",
formatManagement: "Opmaakbeheer",
bottomTotalColumn: "Onderste totaalkolom",
formatType: "Opmaaktype",
datagridSettingsReset: "De instellingen van het gegevensraster worden gereset",
unexpectedError: "Sorry, er is een onverwachte fout opgetreden.",
changesSaved: "Wijzigingen zijn opgeslagen",
addGantTask: "Gantt-taak toevoegen",
updGantTask: "Gantt-taak bijwerken",
updSchedulerTask: "Agendataak bijwerken",
updTodoTask: "Taak in de taaksectie bijwerken",
completeTask: "Taak voltooien",
cantBeEmptyKanban: "De taaknaam, taakdetails en taaktoewijzingen mogen niet leeg zijn.",
successAdd: "De taak is succesvol toegevoegd.",
successUpd: "De taak is succesvol bijgewerkt.",
statusChangeTodo: "De status van de taak is succesvol gewijzigd.",
reminderSended: "Herinneringsmail voor de taak succesvol verzonden.",
askForCompleteTask: "Weet u zeker dat u de taak wilt voltooien?",
infoForCompletedTask: "De subtaken van uw taak worden ook als voltooid beschouwd.",
noPermisison: "U hebt geen toestemming.",
successDependencyAdded: "Taakafhankelijkheid succesvol toegevoegd.",
filterimportant: "Filteren op belangrijkheid",
enterEmail: "Voer een e-mailadres in",
reply: "Beantwoorden",
newCol: "Nieuwe kolom",
thickness: "Dikte",
distanceToUpperComponent: "Afstand tot bovenste component",
companyName: "Bedrijfsnaam",
showEmailModule: "E-mailmodule weergeven",
distanceToBottomComponent: "Afstand tot onderste component",
canNonAdminUsersPost: "Kunnen niet-beheerders berichten plaatsen",
columnColor: "Kolomkleur",
byWho: "door",
updated: "Bijgewerkt",
commentAdded: "Reactie toegevoegd",
completed: "Voltooid",
addRemoveColumns: "Kolommen toevoegen/verwijderen",
searchMessage: "Zoeken in berichten",
riverReports: "River BI-rapporten",
forms: "Formulieren",
 searchinProcess: "zoeken in processen",
searchVoiceMessage: "Het bericht dat u zoekt, bevindt zich in dit audiobestand.",
searchVoiceMessages: "Doorzoek spraakberichten",
showSocialNotifications: "Sociale meldingen weergeven",
showProjectManagementNotifications: "Meldingen voor projectbeheer weergeven",
showProcessNotifications: "Procesmeldingen weergeven",
pageDesingPermission: "De gebruiker heeft geen toestemming om pagina's te ontwerpen.",
filemanagementDesingPermission: "De gebruiker heeft geen toestemming voor bestandsbeheer.",
taskDelete: "De taak wordt verwijderd.",
tasksWillDeleted: "Weet u zeker dat u de taak wilt verwijderen?",
componentSettings: "Componentinstellingen",
aboutComponent: "Over het component",
sureSaveChanges: "Weet u zeker dat u uw wijzigingen wilt opslaan?",
showDeletedNotifications: "Verwijderde meldingen weergeven",
shareSometthing: "Iets delen",
resetPageLayout: "Pagina-indeling resetten",
downloadingSS: "Screenshot wordt gedownload. Even geduld a.u.b.",
showHorizontalLines: "Horizontale lijnen weergeven",
turnOnVerticalHide: "Verticale verberging inschakelen",
        whichHorizontalLinelinesAreNotVisible: "Welke horizontale lijn(en) wilt u niet weergeven?",
        priority: "Prioriteit",
        boardSidebarMessageField: "Taakbericht Veld",
        boardSidebarExtraField: "Taak Extra Component Veld",
        boardSidebarTaskHistory: "Taakgeschiedenis",
        loadMoreNotifications: "Meer Meldingen Weergeven",
        deletedNotifications: "Verwijderde Meldingen",
        noFormNotification: "Nog Geen Procesmeldingen",
        noDeletedFormNotifications: "Geen Verwijderde Procesmeldingen",
        noDeletedBoardNotification: "Geen Verwijderde Bordmeldingen",
        noBoardNotification: "Nog Geen Bordmeldingen",
        noDeletedSocialNotification: "Geen Verwijderde Sociale Media Meldingen",
        noSocialNotification: "Nog Geen Sociale Media Meldingen",
        deleteAll: "Alles Verwijderen",
        markAllRead: "Alles Als Gelezen Marker", board: "Bord",
        formNotifications: "Formuliermeldingen",
        boardNotifications: "Bordmeldingen",
        socialNotifications: "Sociale Meldingen",
        form: "Formulier",
        email: "E-mail",
        oldFormName: "Oude Formulier Naam",
        newFormName: "Nieuwe Formulier Naam",
        oldFormVariable: "Oude Formulier Variabele",
        newFormVariable: "Nieuwe Formulier Variabele",
        oldCategory: "Oude Categorie",
        newCategory: "Nieuwe Categorie",
        connectionInFile: "Verbinding in Bestand",
        yourConnection: "Uw Verbinding",
        element: "Element",
        script: "Script",
        formClosingEvent: "Formulier Sluitingsgebeurtenis",
        message: "Bericht",
        formExportTextError: "Er zijn geen componenten toegevoegd aan het formulier of het formulier is niet opgeslagen. Los dit probleem op om te voorkomen dat de operatie mislukt.",
        checkRequiedFields: "Verplichte Velden Controleren",
        dontCheckRequiredFields: "Verplichte Velden Niet Controleren",
        createPdfWarning: "Indien aangevinkt, wordt het formulier niet opgeslagen en gesloten",
        cratePdf: "PDF Maken",
        identityNumber: "Identificatienummer",
        minPaymentHour: "Minimale Uur",
        maxPaymentHour: "Maximale Uur",

        darkBlue: "Donkerblauw",
        orange: "Oranje",
        component: "Component",
        properties: "Eigenschappen",
        dmsPath: "DMS Pad",
        dashboard: "Dashboard",
        slider: "Schuifregelaar",
        list: "Lijst",
        otherImages: "Andere Afbeeldingen",
        unitScript: "Eenheid Script",
        updatableMaxQuantity: "Bij te werken Max Hoeveelheid",
        newPage: "Nieuwe Pagina",
        code: "Code",
        resetHiddenComponent: "Waarde van Verborgen Doelcomponenten Herstellen",
        bold: "Vet",
        italic: "Cursief",
        stringFormat: "Tekstindeling",
        videoUploaded: "Video Geüpload",
        fillInAllFields: "Vul Alle Velden In",
        normalPaging: "Normaal Pagineren",
        pageSize: "Paginaformaat",
        special: "Speciaal",
        link: "Link",
        fontWeight: "Lettertype Gewicht",
        pdfFileNotFound: "PDF Bestand Niet Gevonden",
        themeMenuHidden: "Verborgen Menu",
        addedTask:"toegevoegde taak",
        likedOn:"leuk gevonden",
        updatedTask: "taak met de naam bijgewerkt",
        messageAddedToTask: "Bericht toegevoegd aan taak",
        deleteTask: "genoemde taak is verwijderd",
        completedTask: "taak volbracht",
        subCategory: "Subcategorie",
        toTheBoard: "naar het bord",
        hideTaskDetail: "Taakdetails Verbergen",
        selectData: "Gegevens selecteren",
        noContentYet: "Nog Geen Inhoud",
        addRowBetween: "Rij Toevoegen Tussen",
        userPermissions: "Gebruikersrechten",
        askForPassword: "Wachtwoord vragen?",
        passwordIsWrong: "Wachtwoord is onjuist, probeer het opnieuw.",
        pleaseEnterPassword: "Voer uw wachtwoord in om door te gaan.",
        onePaymentEachSave: "Een betaling voor elke opslag",  
        riverModules: "RIVER Modules",
        processHistory: "procesgeschiedenis",
        commentHasBeenMadeAtThisStage: "Er is in dit stadium commentaar toegevoegd",
        clearSelection: "Selectie Wissen",
        allowDownloadVersionHistoryDocuments: "Downloaden van Versiegeschiedenis Documenten Toestaan",

        delegated: "gedelegeerd",
        openDocxToPdf: "Bestand Openen als PDF",
        activationStartDate: "Activeringsdatum Start",
        activationEndDate: "Activeringsdatum Einde",
        changeActivationDate: "Activeringsdatum Wijzigen",
        showArchive: "Archief Weergeven",
        hideArchive: "Archief Verbergen",
        tasks: "Taken",
        maxFileCountLimit: "Maximaal aantal bestanden",
        fileCountLimit: "Bestandslimiet",
        numberOfFilesExceeded: "Aantal bestanden overschreden",
        numberOfFilesLimit: "Aantal bestanden limiet",
        lineIntermediateAllowAdd: "Tussenliggende regel toevoegen",
        lineIntermediateAllowCopy: "Tussenliggende regel kopiëren",
        triggerReport: "Triggerrapport",
        setWhitePageReportExportName: "stel de exportnaam van het witte paginarapport in",
        menuRowUpdated: "Er zijn records met een automatisch geïncrementeerde waarde. Het automatisch geïncrementeerde nummer is bijgewerkt: ",
        isMenuRowControl: "Auto-Incrementbesturing",
        sqlScriptWarning: "Het gebruik van dit veld kan leiden tot prestatieverlies.",

        deletedTask: "Verwijderde Taak",
        getDeletedTasks: "Verwijderde Taken Ophalen",
        getTasks: "Taken Ophalen",
        movePageDesignersNode: "Geselecteerde Bord/Menu Wordt Verplaatst naar Map",
        informationAboutMoveToDirectory: "De gebruikersrechten van het verplaatste Bord/Menu zullen veranderen op basis van de rechten van de map waar het naartoe is verplaatst.",
        move: "Verplaatsen",
        download: "Downloaden",
        create: "Maken",
        statistic: "Statistiek",
        priRate: "Toewijzingspercentage op basis van prioriteit",
        taskcreatorlist: "Lijst met makers van taken",
        completionRate: "Voltooiingspercentage",
        columnsTaskCount: "Aantal taken in kolommen",
        showKanbanFilter: "Toon Kanban-filters",
        processToStart: "Te starten proces",
        fieldMatching: "Veldovereenkomst",
        dontLetDeleteWhatTheyAdded: "Ze kunnen verwijderen wat ze hebben toegevoegd",
        showFileHistory: "Toon bestandsgeschiedenis",
        myJobs: "Mijn banen",
        excelExportName:"Excel-exportnaam",
        makeRequiredField: "Maak veld vereist",
        makeReadOnly: "Maak alleen-lezen",
        letItWorkProcessContinues: "Laat het werken als het proces doorgaat",
        event: "Gebeurtenis",
        selectEvent: "Selecteer Gebeurtenis",
        rowEditor: "Rij-editor",
        formOpeningComponentEvent: "Formulieropening Componentgebeurtenis",
        filterSearch: "Zoeken in taken",
        filterForAssignUsers: "Filteren op toegewezen gebruiker",
        filterForAssignUGroups: "Filteren op groep",
        filterForPri: "Filteren op prioriteit",
        clearFilter: "Filters wissen",
        boardToastNotification: "{{0}}   heeft taak {{2}}   uit tabel  {{1}} {{3}} ",
        socialToastNotification: "{{0}}  vond je bericht met  {{1}} inhoud leuk op   {{2}}",
        processToastNotification: "{{0}} heeft gereageerd op het formulier  {{1}}",
        yesterday: "Gisteren",
        today: "Vandaag",
        notSaved: "Niet opgeslagen",
        editors: "Editors",
        basic: "Basis",
        file: "Bestand",
        datagridPreferences: "Datagrid Voorkeuren",
        deleteLetsAsk: "Verwijderen toestaan?",
        addNewRow: "Zonder te vragen, toevoegen als nieuwe rij",
        oldRowShouldBeDeleted: "Zonder te vragen, oude rijen verwijderen",
        canBeSearched: "Kan worden gezocht?",
        processName: "proces naam",
        removeReadOnly: "Schrijfbeveiliging verwijderen",
        dontMakeRequired: "Maak van veld geen vereiste",
        dataName: "Gegevensnaam",
        margin: "Marge",
        rowCount: "Aantal rijen",
        colCount: "Aantal kolommen",
        outsideMargin: "Buitenmarge",
        widgetName: "Widget Naam",
        searchInProcess: "Formulieren verwerken",


        changeEventScriptRun: "Moeten wijzigingsscripts worden geactiveerd wanneer gegevens zijn ingevuld?",
        dontChanceId: "(Deze object-ID kan niet worden gewijzigd omdat deze wordt gebruikt in Component Form Activities.)",
        scriptButtonWarning: "Statusinstellingen voor scriptknoppen worden niet ondersteund in menuformulieren.",
        description2: "verklaring 2",
        showJobListTabs: "toon de Taaklijst- tabbladen",
        showUserPhotosInJobList: "toon gebruikersfoto's in takenlijst",
        dmsVideoGaleryTabWarning: "U hebt geen toestemming om dit veld te gebruiken.",
        searchInFormJson: "Zoek in formulier json",
        useExpandedJoblist: "Gebruik uitgebreide takenlijst",
        editTour: "Tour bearbeiten",
        selectComponent: "Komponente auswählen",
        tourComponents: "Tour-Komponenten",
        addTourElement: "Tour-Element hinzufügen",
        startTourWhenFormOpen: "Tour starten, wenn das Formular geöffnet ist",
        compare: "Vergleichen",
        deleteTourElement: "Tour-Element löschen",
        tourWillDeleted: "Tour wird gelöscht",
        tourDelete: "Tour löschen",
        fixedRowHeight: "Fast rækkehøjde",
        pushWidgetItems: "Skub Widget-elementer",
        allowMultiLayer: "Tillad flere lag",
        gridType: "Gittertype",
        useCollapsedJoblist: "gebruik de samengevouwen takenlijst",
        searchAllData: "Zoek alle gegevens",
        scriptsRunAllAreSelected: "Moeten scripts worden uitgevoerd wanneer alles is geselecteerd?",
        
        menuSchedulerPermission: "U heeft geen toestemming om een menukaart toe te voegen",
        showOnTask: "Moet de redacteur verschijnen in de processen",
        alreadyHaveMenu: "Er is al een menucomponent",
        select: "Selecteer",
        startedUserName: "Gestarte gebruikersnaam",
        startedUserId: "Gebruikers-ID gestart",
        processInstanceId: "Procesinstantie-ID",
        formId: "Formulier-ID",
        min: "Minimum",
        labelNotFound:"Label is niet gedefinieerd in deze taal",
    }
  
  }