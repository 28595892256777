import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { AppInfoService } from '@shared/services/common/app-info.service';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { PageModel } from '@shared/models/page-designer';
import { MailService } from './mail.service';

@Injectable({
    providedIn: 'root'
})
export class PageDesignerService implements Resolve<any>{

    public onPagesChange: BehaviorSubject<any>;
    public onUsersChange: BehaviorSubject<any>;
    public onGroupsChange: BehaviorSubject<any>;
    public onColumnsChange: BehaviorSubject<any>;
    public onTagsChange: BehaviorSubject<any>;
    public onSelectedPageIdChange: BehaviorSubject<number>;

    public pages: any[];
    public users: any[];
    public groups: any[];
    public columns: any[];
    public tags: any[];
    public selectedPageId: any;

    public kanbanReorderColumn = "";

    constructor(public appInfo: AppInfoService, private http: HttpClient, private _mailService: MailService) {
        this.onPagesChange = new BehaviorSubject({});
        this.onUsersChange = new BehaviorSubject({});
        this.onGroupsChange = new BehaviorSubject({});
        this.onColumnsChange = new BehaviorSubject({});
        this.onTagsChange = new BehaviorSubject({});
        this.onSelectedPageIdChange = new BehaviorSubject(0);

        this.columns = [
            { id: 1, name: 'Planlananlar', isUpdated: false, isCreated: false, css: { backgroundColor: '#DBCFB0', color: '#ffffff' } },
            { id: 2, name: 'Başlayanlar', isUpdated: false, isCreated: false, css: { backgroundColor: '#BFC8AD', color: '#ffffff' } },
            { id: 3, name: 'Tamamlananlar', isUpdated: false, isCreated: false, css: { backgroundColor: '#90B494', color: '#ffffff' } }
        ]

        this.tags = [
            { id: 1, name: 'Arge', isUpdated: false, isCreated: false, css: { backgroundColor: '#ff00ff', color: '#f0f0f0' } },
            { id: 2, name: 'Bug Fix', isUpdated: false, isCreated: false, css: { backgroundColor: '#ff0000', color: '#f0f0f0' } },
            { id: 3, name: 'Talep', isUpdated: false, isCreated: false, css: { backgroundColor: '#ffff00', color: '#f0f0f0' } }
        ]

        this.onColumnsChange.next(this.columns)
        this.onTagsChange.next(this.tags)
    }

    changePageId(page: number) {
        this.onSelectedPageIdChange.next(page);
    }

    // Api
    /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        return new Promise<void>((resolve, reject) => {
            Promise.all([this.getUsers(), this.getGroups(), this.getPages(), this.getColumns()]).then(() => {
                resolve();
            }, reject);
        });
    }

    /**
    * Get Todos Assignee
    */
    getPages(): Promise<PageModel[]> {
         
        return new Promise((resolve, reject) => {
            this.http.get<any>(this.appInfo.appUrl + "api/pagedesigner/get", this.appInfo.httpOptions).subscribe((pages: PageModel[]) => {
                this.pages = pages;
                this.onPagesChange.next(this.pages);
                resolve(this.pages);
            }, reject);
        });
    }

    /**
    * Get Todos Assignee
    */
    getPageById(pageId): Promise<PageModel> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(this.appInfo.appUrl + "api/pagedesigner/get/" + pageId, this.appInfo.httpOptions).subscribe((page: PageModel) => {
                resolve(page);
            }, reject);
        });
    }

    getPageIdById(pageId) {
        return this.http.get<any>(this.appInfo.appUrl + "api/pagedesigner/getpagename?pageId=" + pageId, this.appInfo.httpOptions);
    }
    
    getPagesByUserId(userId: number): Promise<PageModel[]> {
         
        return new Promise((resolve, reject) => {
            this.http.get<any>(this.appInfo.appUrl + "api/pagedesigner/getmenupages?userId=" + userId, this.appInfo.httpOptions).subscribe((pages: PageModel[]) => {
            resolve(pages)
            }, reject);
        });
    }

    /**
    * Get Todos Assignee
    */
    getColumns(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this.onPagesChange.next(this.columns);
            resolve(this.columns);
        });
    }

    /**
    * Get Todos Assignee
    */
    getUsers(): Promise<any[]> {
        return new Promise((resolve, reject) => {

            this.http.get(this.appInfo.appUrl + 'api/todo/users', this.appInfo.httpOptions).subscribe((users: any) => {
                this.users = users;
                this.onUsersChange.next(this.users);
                resolve(this.users);
            }, reject);
        });
    }

    /**
     * Get Todos Assignee
     */
    getGroups(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this.http.get(this.appInfo.appUrl + 'api/todo/groups', this.appInfo.httpOptions).subscribe((groups: any) => {
                this.groups = groups;
                this.onGroupsChange.next(this.groups);
                resolve(this.users);
            }, reject);
        });
    }

    savePages(list: PageModel[], assignedUsers: any, assignedGroups: any): Promise<any[]> {
         debugger
        return new Promise((resolve, reject) => {
            if (list.length > 0 || assignedUsers || assignedGroups) {
                const url = this.appInfo.appUrl + 'api/pagedesigner/save/all?userId=' + this.appInfo.currentUser.id + ( assignedUsers ? ('&assignedUsers=' + assignedUsers) : ('')) + (assignedGroups ? ('&assignedGroups=' + assignedGroups) : (''))
                this.http.post(url, JSON.stringify(list), this.appInfo.httpOptions).subscribe((data: any) => {
                    resolve(data);
                    this.getPages();
                }, reject);
            } else {
                resolve(null);
            }

        });
    }

    getKanbanFormIdByPageId(pageId: number) {
        return new Promise((resolve, reject) => {
            if (pageId == 0) {
                this.http.get(this.appInfo.appUrl + 'api/kanban/getkanbanformidbypageid?pageid=' + pageId, this.appInfo.httpOptions).subscribe((data: any) => {
                    resolve(data);
                }, reject);
            } else {
                resolve(null);
            }

        });
    }
    getAssignedUsersByPageId(pageId: number) {
         

        return this.http.get(this.appInfo.appUrl + 'api/pagedesigner/getassignedusersbypageid?pageid=' + pageId, this.appInfo.httpOptions);
   }
    

    getKanbanColumns(pageId: number,userId:number) {
        return new Promise((resolve, reject) => {
            if (pageId != 0) {
                this.http.get(this.appInfo.appUrl + 'api/kanban/getkanbancolumnsbypageid?pageid=' + pageId +'&userId='+userId, this.appInfo.httpOptions).subscribe((data: any) => {
                    resolve(data);
                }, reject);
            } else {
                resolve([]);
            }

        });
    }

    saveCustomPage(item: any,userId:number) {
        return new Promise((resolve, reject) => {
            this.http.post(this.appInfo.appUrl + 'api/page/custom/post?userId=' + userId, JSON.stringify(item), this.appInfo.httpOptions).subscribe((data: any) => {
                resolve(data);
            }, reject);
        });
    }

    getCustomPage(type: number, userId: number,pageId:number) {
        return new Promise((resolve, reject) => {
            if (type != 0 && userId != 0) {
                this.http.get(this.appInfo.appUrl + 'api/page/custom/get?type=' + type + '&userId=' + userId + '&pageId=' + pageId, this.appInfo.httpOptions).subscribe((data: any) => {
                    resolve(data);
                }, reject);
            } else {
                resolve(null);
            }

        });
    }

    deletePage(pageId: number) {
         
        return this.http.get<boolean>(this.appInfo.appUrl + "api/pagedesigner/deletepagebypageid?pageId=" + pageId, this.appInfo.httpOptions);

    }


}
