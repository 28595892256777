import { ProfileComponent } from '../../../../../pages/profile/profile.component';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { Component, ElementRef, EventEmitter, HostListener, Inject, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { SearchService } from './search.service';
import {
    InstanceService,
    TaskService,
    GenericService,
    ReportService,
    AuthService,
    UserService
} from '@shared/services/api';
import { ProfileService } from '../../../../../pages/profile/profile.service';
import { AppInfoService, ComponentService, EventEmitterService } from '../../../../../../@shared/services/common';


@Component({
  selector: 'app-navbar-search',
    templateUrl: './navbar-search.component.html'
  
})
export class NavbarSearchComponent implements OnInit {
  // Public
  public searchText = '';
  public openSearchRef = false;
  public activeIndex = 0;
    public apiData;
    public canSearch: boolean;
    public showsearchinProcess: boolean = false;
  public pages = [];
    public files = []; 
    public adminUsers = [];
  public contacts:any = [];
    public pageSearchLimit;
    private currentRoute = "";
    schemaList: any[] = [];
    keyword: string;
    @Output() openSearchPopup = new EventEmitter<boolean>();
  // Decorators
  @ViewChild('openSearch') private _inputElement: ElementRef;
  @ViewChild('pageList') private _pageListElement: ElementRef;
    showContainer: boolean = true;
    showSearchPopup: boolean = false;
    taskListFilter: { schemaId: number; keyword: string; start1: string; start2: string; end1: string; end2: string; status: number; userId: number; insideSearch: number; };
    filter: any = {
        schemaId: 0,
        keyword: "",
        start1: null,
        start2: null,
        end1: null,
        end2: null,
        status: 0,
        userId: 0,
        insideSearch: false
    }
   
  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openSearchRef = false;
    this.searchText = '';
  }
  @HostListener('document:click', ['$event']) clickout(event) {
    if (event.target.className === 'content-overlay') {
      this.removeOverlay();
      this.openSearchRef = false;
      this.searchText = '';
    }
  }
    languageLocal: any = "";

  /**
   *
   * @param document
   * @param router
   * @param _searchService
   */
  constructor(
    @Inject(DOCUMENT) private document,
    private _elementRef: ElementRef,
    private router: Router,
      public _searchService: SearchService,
      private _userService: UserService,
      private _profileService: ProfileService,
      private eventEmitterService: EventEmitterService,
      private taskService: TaskService,
      public componentService: ComponentService,
      private authService: AuthService,
      public appInfo : AppInfoService

  ) {
      this.languageLocal = this.componentService.getLanguageSource(); //language 
      this.currentRoute = this.router.url;
      this.checkRoute()

      this.router.events.subscribe((event: any) => {

          if (event instanceof NavigationEnd) {

              // Hide progress spinner or progress bar
              this.currentRoute = event.url;
              this.checkRoute()
          }


      });
    }       

    checkRoute() {
        if (this.currentRoute.includes('profile')) {
            this.showsearchinProcess = true;
        }
        else {
            this.showsearchinProcess = false;
        }

    }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Next Active Match
   */
  nextActiveMatch() {
    this.activeIndex = this.activeIndex < this.pageSearchLimit - 1 ? ++this.activeIndex : this.activeIndex;
  }

  /**
   * Previous Active Match
   */
  prevActiveMatch() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
  }

  /**
   * Remove Overlay
   */
  removeOverlay() {
    this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  /**
   * Auto Suggestion
   *
   * @param event
   */
  autoSuggestion(event) {
    if (38 === event.keyCode) {
      return this.prevActiveMatch();
    }
    if (40 === event.keyCode) {
      return this.nextActiveMatch();
    }
    if (13 === event.keyCode) {
      // Navigate to activeIndex
      // ! Todo: Improve this code
      let current_item = this._pageListElement.nativeElement.getElementsByClassName('current_item');
      current_item[0]?.children[0].click();
    }
  }

  /**
   * Toggle Search
   */
  toggleSearch() {
    this._searchService.onIsBookmarkOpenChange.next(false);
    this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.activeIndex = 0;
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    });

      if (this.openSearchRef === false) {
          this.document.querySelector('.app-content').classList.remove('show-overlay');
          this.searchText = '';
      } else {
          this.document.querySelector('.app-content').classList.add('show-overlay');
      }
  }

  /**
   * Search Update
   *
   * @param event
   */
  searchUpdate(event) {
      const val = event.target.value.toLowerCase();
    //Search sürekli gözüksün istendiği için kaldırıldı.
    //if (val !== '') {
      //this.document.querySelector('.app-content').classList.add('show-overlay');
    //} else {
      //this.document.querySelector('.app-content').classList.remove('show-overlay');
    //}
    this.autoSuggestion(event);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
    ngOnInit(): void {
        this.authService._isLoggedIn.subscribe(data => {
            if (data) {
                this._searchService.getCanSearchUsers().subscribe(value => {
                 
                    this.canSearch = value;
                })
               


            }
        })

        this._userService.onUsersChanged.subscribe(data => {
            this.adminUsers = [];
            data.forEach(user => {
                if (user.isadmin) {
                    this.adminUsers.push(user.id);
                }

            })
           

        
           
        })


    this._searchService.onApiDataChange.subscribe(res => {
        this.apiData = res;
        
        this._userService.onUsersChanged.subscribe(data => {
            data.map(f => {
                f.custom = f.photo != null && f.photo != '' ? 'data:image/jpeg;base64,' + f.photo : null
            })

            this.contacts = data;
           
        })

    });
    }

    
    goToProfile(contactId: number) {
        this.router.navigate(['app/profile']);
        this._profileService.changeSelectedUser(contactId);
       
    }

    showSearch() {
        
        this.showSearchPopup = !this.showSearchPopup;
        if (this.showSearchPopup) {
            this.eventEmitterService.openSearchPopup(true);
        }
        else {
            this.eventEmitterService.openSearchPopup(false);
        }
    }

    




    
}
