import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { AppInfoService, ComponentService, EventEmitterService } from '../../../@shared/services/common';
import { AuthService, TaskService } from '../../../@shared/services/api';
import { CoreTranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from '../../../app/pages/profile/profile.service';
import { jobListControlsVm } from '../../../app/pages/home/joblist_chat.model';
import { Router } from '@angular/router';

@Component({
    selector: 'core-theme-customizer',
    templateUrl: './theme-customizer.component.html',
    styleUrls: ['./theme-customizer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CoreThemeCustomizerComponent implements OnInit, OnDestroy {
    navbarColorValue: string;
    coreConfig: any;
    form: FormGroup;

    // Private
    private _unsubscribeAll: Subject<void>;
    hideJobList: boolean = false;
    currentRoute: any;

    showJoblistUserPhotos: boolean = true;
  //  public languageLocal: any = ""
    /**
     * Constructor
  
     * @param {FormBuilder} _formBuilder
     * @param {CoreConfigService} _coreConfigService
     * @param {CoreSidebarService} _coreSidebarService
     * */
    constructor(
        private _formBuilder: FormBuilder,
        private _coreConfigService: CoreConfigService,
        private _coreSidebarService: CoreSidebarService,
        private componentService: ComponentService,
        private authService: AuthService,
        public changeDetectorRef: ChangeDetectorRef,
        public appInfo: AppInfoService,
        private taskService: TaskService,
        public eventEmitterService: EventEmitterService,
        private _coreTranslationService: CoreTranslationService,
        public _translateService: TranslateService,
        private route: Router,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.currentRoute = this.route;
        
        authService._isLoggedIn.subscribe(data => {
            if (data == true) {   
                if(this.currentRoute.url.includes('app/home')) {
                    this.applyHeaderFilterValues();
                }
                //this.componentService.languageSourceObject = JSON.parse(localStorage.getItem("languageSourceStorage"));
                //this.componentService.languageSource = this.componentService.languageSourceObject.langSource;
                //this.languageLocal = this.componentService.languageSourceObject.langSource;
            }                 
        })
       
    }

    //  Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Build theme config form
         
        
       
        this.form = this._formBuilder.group({
            app: this._formBuilder.group({
                appName: new FormControl(),
                appTitle: new FormControl(),
                appLogoImage: new FormControl(),
                appLanguage: new FormControl()
            }),
            layout: this._formBuilder.group({
                skin: new FormControl(),
                type: new FormControl(),
                animation: new FormControl(),
                menu: this._formBuilder.group({
                    hidden: new FormControl(),
                    collapsed: new FormControl()
                }),
                navbar: this._formBuilder.group({
                    hidden: new FormControl(),
                    type: new FormControl(),
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    backgroundColor: new FormControl()
                }),
                footer: this._formBuilder.group({
                    hidden: new FormControl(),
                    type: new FormControl(),
                    background: new FormControl(),
                    customBackgroundColor: new FormControl(),
                    backgroundColor: new FormControl()
                }),
                enableLocalStorage: new FormControl(),
                customizer: new FormControl(),
                scrollTop: new FormControl(),
                buyNow: new FormControl()
            })
        });

        // Subscribe to the config changes
        this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            // Update config
            this.coreConfig = config;

            // Set the config form values
            this.form.setValue(config, { emitEvent: false });
        });

        // Subscribe to the form layout.type value changes
        this.form
            .get('layout.type')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(value => {
                this._resetFormValues(value);
            });

        // Subscribe to the form value changes
        this.form.valueChanges.pipe(takeUntil(this._unsubscribeAll)).subscribe(config => {
            this._coreConfigService.config = config;
        });

        // Set navbar color
        this.navbarColor(this.form.get('layout.navbar.backgroundColor').value);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    //  Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Reset form values based on the selected menu layout
     *
     * @param value
     * @private
     */
    private _resetFormValues(value): void {
        switch (value) {
            case 'vertical': {
                this.form.patchValue({
                    layout: {
                        // skin: 'default',
                        animation: 'fadeIn',
                        menu: {
                            hidden: false,
                            collapsed: false
                        },
                        navbar: {
                            hidden: false,
                            type: 'floating-nav',
                            background: 'navbar-light',
                            customBackgroundColor: true,
                            backgroundColor: ''
                        },
                        footer: {
                            hidden: false,
                            type: 'footer-sticky',
                            background: 'footer-light',
                            customBackgroundColor: false,
                            backgroundColor: 'bg-primary'
                        }
                    }
                });
            }
            case 'horizontal': {
                this.form.patchValue({
                    layout: {
                        // skin: 'default',
                        animation: 'fadeIn',
                        menu: {
                            hidden: false,
                            collapsed: false
                        },
                        navbar: {
                            hidden: false,
                            type: 'floating-nav',
                            background: 'navbar-light',
                            customBackgroundColor: true,
                            backgroundColor: ''
                        },
                        footer: {
                            hidden: false,
                            type: 'footer-sticky',
                            background: 'footer-light',
                            customBackgroundColor: false,
                            backgroundColor: 'bg-primary'
                        }
                    }
                });
            }
        }
        //console.log(this.navbarColorValue);

        // Set navbar color
        this.navbarColor(this.form.get('layout.navbar.backgroundColor').value);
    }

    // Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Patch selected navbar color value to form
     *
     * @param value
     */
    navbarColor(value): void {

        this.navbarColorValue = value;
        this.form.patchValue({
            layout: { navbar: { customBackgroundColor: true, backgroundColor: this.navbarColorValue } }
        });
        localStorage.setItem("customNavbarColor", JSON.stringify(this.navbarColorValue));
        this.eventEmitterService.navbarColor(value);
    }

    
    navbarBg(): void {
        
        this.navbarColorValue = '';
        this.navbarColor(this.navbarColorValue);
    }
    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebar(key): void {
        this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
    }

    toggleSidebarCollapsible(collapsed): void {
        // Get the current menu state

        collapsed = !collapsed;
        this.eventEmitterService.setSidebarCollapsible(collapsed);
    }
    applyHeaderFilterValues() {
        let _localUserSession = JSON.parse(localStorage.getItem("localUserSession"));
        if (typeof _localUserSession.currentUser.jobListControls.hideTabs == "undefined" || typeof _localUserSession.currentUser.jobListControls.showPhotos == "undefined") {
            this.hideJobList = false;
            this.showJoblistUserPhotos = true;
        }
        else {
            this.hideJobList = _localUserSession.currentUser.jobListControls.hideTabs;
            this.showJoblistUserPhotos = _localUserSession.currentUser.jobListControls.showPhotos;
        }
    }

    showUserPhoto(event: any) {
        let _localUserSession = JSON.parse(localStorage.getItem("localUserSession"));
        this.showJoblistUserPhotos = !this.showJoblistUserPhotos;
        _localUserSession.currentUser.jobListControls.showPhotos = this.showJoblistUserPhotos;
        localStorage.setItem("localUserSession", JSON.stringify(_localUserSession));
        this.taskService.setJobListUserPhotoSettingsData(this.appInfo.currentUser.id, this.showJoblistUserPhotos).subscribe(data => {
            if (data) {
                this.eventEmitterService.joblistShowUserPhotosData(this.showJoblistUserPhotos);
            }
        });

    }

    hideJoblistTabs(event: any) {
        let _localUserSession = JSON.parse(localStorage.getItem("localUserSession"));
        this.hideJobList = !this.hideJobList;
        _localUserSession.currentUser.jobListControls.hideTabs = this.hideJobList;
        localStorage.setItem("localUserSession", JSON.stringify(_localUserSession));
        this.taskService.setUserHideJoblistData(this.appInfo.currentUser.id, this.hideJobList).subscribe(data => {
            if (data) {
                this.eventEmitterService.hideJobListTabs(this.hideJobList);
            }
        })
    }


}
