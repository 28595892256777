export const locale = {

    lang: 'RURU',

    data: {
        loginButton: "Вход",
        userName: "Имя пользователя",
        pass: "Пароль",
        remember: "Запомнить меня",
        forgotPass: "Забыли пароль",
        modules: "Модули",
        generic: "Общее",
        search: "Поиск",
        logout: "Выход",
        waitingJob: "Ожидающие задачи",
        myStarted: "Начатые мной",
        myIncluded: "Мои задачи",
        myCompleted: "Завершенные мной",
        jobList: "Список задач",
        formNo: "Номер формы",
        status: "Статус",
        starter: "Начало",
        assigned: "Назначено",
        timeoutDate: "Дата тайм-аута",
        startDate: "Дата начала",
        processing: "Обработка",
        you: "вы",
        history: "История",
        description: "Описание",
        date: "Дата",
        growShrink: "Увеличить/уменьшить",
        close: "Закрыть",
        gridReset: "Сброс настроек сетки",
        columnSelect: "Выбор колонки",
        jobName: "Имя задачи",
        keyword: "Ключевое слово",
        completion: "Завершение",
        all: "Все",
        completedDate: "Дата завершения",
        completions: "Завершено",
        ongoing: "В процессе",
        formName: "Имя формы",
        sunday: "Воскресенье",
        monday: "Понедельник",
        tuesday: "Вторник",
        wednesday: "Среда",
        thursday: "Четверг",
        friday: "Пятница",
        saturday: "Суббота",
        pdfExport: "Экспорт в PDF",
        sendPassword: "Отправить пароль",
        assignedDate: "Дата назначения",
        events: "События",
        new: "Новый",
        save: "Сохранить",
        formula: "Формула",
        selectDataSource: "Выбор источника данных",
        requiredField: "Обязательное поле",
        suffix: "Суффикс",
        valueColumn: "Колонка значений",
        showLength: "Показать количество столбцов",
        clearButton: "Очистить",
        allCaps: "Все заглавные",
        autoAsc: "Автосортировка",
        width: "Ширина",
        length: "Длина",
        functions: "Функции",
        selectElement: "Выбрать элемент",
        selectColumn: "Выбор колонки",
        edit: "Редактировать",
        changeEventActions: "Изменить действия события",
        barcode: "Штрих-код",
        systemInfo: "Системная информация",
        prefix: "Префикс",
        max: "Максимум",
        showMinMaxInfo: "Показать информацию о минимуме и максимуме",
        selectUser: "Выбрать пользователя",
        spinButton: "Кнопка вращения",
        numberIncDecButton: "Кнопка увеличения/уменьшения числа",
        decimal: "Десятичное число",
        numeral: "Число",
        wrongFormat: "Неверный формат",
        height: "Высота",
        microphone: "Микрофон",
        notifyDataReset: "Сброс данных",
        data: "Данные",
        dynamicData: "Динамические Данные",
        manualData: "Ручные Данные",
        selectDataGrid: "Выбрать Таблицу Данных",
        overrideUserChange: "Отменить Изменения Пользователя",
        textColumn: "Текстовый Столбец",
        makeInitialValue: "Сделать Начальное Значение",
        undo: "Отменить",
        copy: "Копировать",
        preview: "Просмотр",
        delete: "Удалить",
        cancel: "Отмена",
        languageSettings: "Настройки Языка",
        messageSqlLinkDeleted: "Файл SQL-команд и адрес соединения будут удалены, так как вы добавили данные вручную. Продолжить?",
        messageSqlLinkDeletedForMenu: "Выбор данных в качестве меню приведет к удалению файла скрипта SQL и адреса ссылки! Желаете продолжить?",
        permissions: "Разрешения",
        objectid: "Идентификатор Объекта",
        fontSize: "Размер Шрифта",
        fontWidth: "Ширина Шрифта",
        fontStyle: "Стиль Шрифта",
        underline: "Подчеркивание",
        textColor: "Цвет Текста",
        visibility: "Видимость",
        textAlignment: "Выравнивание Текста",
        settings: "Настройки",
        messageUnsaved: "Вы уверены, что хотите выйти без сохранения изменений?",
        warning: "Предупреждение!",
        userAuthorizations: "Права Пользователя",
        formPreferences: "Предпочтения Формы",
        formOpeningevent: "Событие Открытия Формы",
        chooseConnectionAddress: "Выберите Адрес Соединения",
        selectPriorty: "Выбрать Приоритет",
        loadingEditor: "Загрузка Редактора...",
        showAddButton: "Показать Кнопку Добавления Столбца",
        update: "Обновить",
        connectionID: "Идентификатор Соединения",
        formPreview: "Предварительный Просмотр Формы",
        nameDisplayedform: "Отображаемое Имя Формы",
        formNamevariable: "Переменная Имени Формы",
        category: "Категория",
        formColor: "Цвет Формы",
        generalForm: "Общая Форма",
        saveForm: "Сохранить Форму",
        messageNeworupdate: "Все изменения на существующей форме будут утеряны. Продолжить?",
        messageDelete: "Вы уверены, что хотите удалить форму?",
        toastDeleted: "Форма Удалена",
        toastError: "Извините, произошла ошибка во время выполнения операции",
        alertDifferentID: "Этот идентификатор уже используется в другом месте, выберите другой.",
        confirmChanges: "Подтвердите Изменения",
        toastUserpermission: "У пользователя нет прав на редактирование формы.",
        toastCodeeditor: "Редактор кода уже существует",
        messageDeletesure: "Элементы внутри этого компонента будут удалены.",
        messageSure: "Уверены?",
        successful: "Успешно",
        error: "Ошибка",
        toastFormupdate: "Форма Обновлена",
        toastMistake: "Извините, произошла ошибка во время выполнения операции",
        toastSaved: "Сохранено",
        toastCopied: "Скопировано",
        toastEmptyFormMessage: "Еще не добавлены элементы формы",
        toastGiveName: "Пожалуйста, дайте имя вашей форме",
        toastGiveVariableName: "Пожалуйста, укажите имя переменной формы",
        toastVariableNameNumberWarning: "Имя переменной не может начинаться с цифры",
        formCopy: "Копировать Форму",
        messageButtonDelete: "Вы уверены, что хотите удалить эту кнопку?",
        noDataFound: "Данные не найдены",
        language: "Язык",
        filterDatagrid: "Фильтрация Таблицы Данных",
        addManualData: "Добавить Ручные Данные во Время Выполнения",
        dateFormat: "Формат Даты",
        messageSqlLinkDelete: "Файл SQL-команд и адрес соединения будут удалены, так как вы добавили данные вручную. Продолжить?",
        timeOption: "Опция Времени",
        defaultTodaysDate: "Сегодняшняя Дата По Умолчанию",
        dateRange: "Диапазон Даты",
        minToday: "Минимум Сегодня",
        maxToday: "Максимум Сегодня",
        dateRangeDay: "Диапазон Даты (Дни)",
        enterDateRangeValue: "Введите значение диапазона даты",
        imageIdColumn: "Идентификатор Столбца",
        statusSettings: "Настройки Статуса",
        active: "Активный",
        passive: "Пассивный",
        enable: "Включить",
        disable: "Отключить",
        visible: "Видимый",
        invisible: "Невидимый",
        items: "Элементы",
        showItems: "Показать Элементы",
        array: "Массив",
        letLabelAppear: "Показать Этикетку",
        add: "Добавить",
        horizontal: "Горизонтальный",
        vertical: "Вертикальный",
        text: "Текст",
        alertFillRequiredFields: "Пожалуйста, заполните обязательные поля",
        alertSignatureField: "Поле для подписи не может быть пустым",
        messageApproveTransaction: "Подтвердить транзакцию?",
        button: "Кнопка",
        reasonforRefusal: "Причина Отказа",
        enterReasonRejection: "Введите причину отказа",
        reject: "Отклонить",
        addDeleteButton: "Добавить/Удалить Кнопку",
        showBorder: "Показать Границу",
        buttons: "Кнопки",
        clean: "Очистить",
        buttonSettings: "Настройки Кнопки",
        saveLocation: "Сохранить Местоположение",
        paymentButton: "Кнопка Оплаты",
        paymentProvider: "Поставщик Оплаты",
        price: "Цена",
        numberOfInstallments: "Количество Инсталляций",
        descriptionField: "Поле Описания",
        current: "Текущий",
        currentForm: "Текущая Форма",
        name: "Имя",
        surname: "Фамилия",
        country: "Страна",
        city: "Город",
        address: "Адрес",
        authorizations: "Авторизации",
        users: "Пользователи",
        user: "Пользователь",
        givePermission: "Предоставить Разрешение",
        groups: "Группы",
        group: "Группа",
        saveandSend: "Сохранить и Отправить",
        approve: "Утвердить",
        sendBack: "Вернуть",
        deslineAndEnd: "Отклонить и Завершить",
        complete: "Завершено",
        blue: "Синий",
        gray: "Серый",
        green: "Зеленый",
        red: "Красный",
        left: "Слева",
        right: "Справа",
        color: "Цвет",
        symbol: "Символ",
        position: "Позиция",
        action: "Действие",
        total: "Итого",
        filePath: "Путь к Файлу",
        fileName: "Имя Файла",
        fileUpload: "Загрузка Файла",
        choose: "Выбрать",
        updateData: "Обновить Данные",
        show: "Показать",
        columns: "Колонки",
        changeEvent: "Изменить Событие",
        columnSettings: "Настройки Колонки",
        columnName: "Имя Колонки",
        deleteColumn: "Удалить Колонку",
        columnSqlScripts: "SQL Скрипты Колонки",
        imageNameColumn: "Имя Файла в Колонке",
        columnLanguageSetting: "Настройки Языка Колонки",
        dataSource: "Источник Данных",
        filter: "Фильтр",
        reset: "Сброс",
        column: "Колонка",
        image: "Изображение",
        searchLocation: "Поиск Местоположения...",
        draggable: "Перетаскиваемый",
        usersCanChange: "Пользователи Могут Изменять",
        multiMarker: "Множественный Маркер",
        markerLimit: "Лимит Маркера (0: Неограниченно)",
        meetingType: "Тип Встречи",
        meetingName: "Имя Встречи",
        loadingFiles: "Загрузка Файлов...",
        fileSizeExceeded: "Превышен Размер Файла",
        filesLoaded: "Файлы Загружены",
        condition: "Условие",
        value: "Значение",
        connectionAddress: "Адрес Подключения",
        imageTypeColumn: "Столбец Типа Файла",
        visibleColumn: "Видимый Столбец",
        selectionMode: "Режим Выбора",
        selectMode: "Режим Выбора",
        datagridFiltering: "Фильтрация Таблицы Данных",
        imageDataColumn: "Столбец Данных изображения",
        selectDataColumn: "Выбрать Столбец Таблицы Данных",
        dataFormat: "Формат Данных",
        photoPreview: "Предпросмотр Фото",
        videoPreview: "Предпросмотр Видео",
        displayPhoto: "Отображать Фото",
        displayVideo: "Отображать Видео",
        imageColumn: "Столбец изображения",
        videoColumn: "Столбец видео",
        columnNameAppear: "Имя Столбца Для Отображения",
        selected: "Выбрано",
        inStock: "В Наличии",
        messageSave: "Вы уверены, что хотите сохранить внесенные изменения?",
        gallery: "Галерея",
        dollar: "доллар",
        sterling: "фунт стерлингов",
        underPhoto: "Под Фото",
        topPhoto: "Над Фото",
        byCategory: "По Категории",
        dashboardShowHide: "Показать/Скрыть Панель",
        showDetail: "Показать Детали",
        hideDetail: "Скрыть Детали",
        decrease: "Уменьшить",
        increase: "Увеличить",
        watch: "Смотреть",
        watchMovie: "Смотреть Фильм",
        noRecordsFound: "Записи не найдены!",
        appearance: "Внешний вид",
        numberofColumns: "Количество Столбцов",
        pagingRecords: "Записи на страницу",
        detailLocation: "Местоположение Деталей",
        hideImages: "Скрыть Изображения",
        quantityCategoryDisable: "Отключить Категорию при Увеличении Количества",
        general: "Общее",
        idColumn: "Столбец Идентификации",
        availableQuantity: "Доступное Количество",
        categoryColumn: "Столбец Категории",
        photoColumn: "Столбец Фотографии",
        titleColumn: "Столбец Заголовка",
        priceColumn: "Столбец Цены",
        updatablePrice: "Обновляемая Цена",
        videoGallery: "Видео Галерея",
        uniqueComponent: "Уникальный Компонент",
        showVariant: "Показать Вариант",
        transferTotalAmount: "Передача Общей Суммы",
        title: "Заголовок",
        hide: "Скрыть",
        detail: "Деталь",
        priceQuery: "Запрос Цены",
        quantityQuery: "Запрос Количества",
        uniqueComponentError: "Ошибка Уникального Компонента",
        joinColumn: "Столбец Объединения",
        nameColumn: "Имя Столбца",
        variantScript: "Скрипт Варианта",
        variantImages: "Изображения Варианта",
        variantJoinColumn: "Столбец Объединения Варианта",
        showAll: "Показать Все",
        showSelected: "Показать Выбранные",
        showByCategory: "Показать По Категории",
        showInStock: "Показать В Наличии",
        notYetAdded: "Еще не добавлено",
        added: "Добавлено",
        startingDate: "Дата Начала",
        endDate: "Дата Окончания",
        themeFeatures: "Особенности Темы",
        menu: "Меню",
        selectMenu: "Выбрать Меню",
        selectSubmenu: "Выбрать Подменю",
        selectMenuBrowser: "Выбрать Меню в Браузере",
        nameElement: "Имя Элемента",
        subject: "Тема",
        picture: "Изображение",
        pictureElement: "Элемент Изображения",
        startingDateElement: "Элемент Начальной Даты",
        endDateElement: "Элемент Даты Окончания",
        usersCanEdit: "Пользователи Могут Редактировать",
        thisPropertyCant: "Это свойство недоступно, если данные берутся из SQL",
        calendarDisplay: "Настройки Отображения Календаря",
        monthly: "Ежемесячно",
        generalDisplayLunarDays: "Общее Отображение Дней Луны",
        agenda: "Повестка Дня",
        listofWeeklyEvents: "Список Еженедельных Событий",
        weekly: "Еженедельно",
        weekend: "Уик-энд",
        generalDisplayWeekDays: "Общее Отображение Рабочих Дней",
        showWeekendDaysWhileWeekdays: "Показывать Уик-энд в Рабочие Дни",
        daily: "Ежедневно",
        listofDailyEvents: "Список Ежедневных Событий",
        startTime: "Время Начала",
        endTime: "Время Окончания",
        changeDefaultColor: "Изменить Стандартные Цвета",
        weekdayColor: "Цвет Рабочих Дней",
        weekendColor: "Цвет Уик-энда",
        eventColor: "Цвет События",
        groupBySql: "Группировать По SQL",
        groupColumnName: "Имя Столбца Группировки",
        assignedColumnName: "Имя Столбца Назначения",
        taskPriortiyColumnName: "Имя Столбца Приоритета Задачи",
        taskSubjectColumnName: "Имя Столбца Темы Задачи",
        taskDetailColumnName: "Имя Столбца Деталей Задачи",
        taskQuery: "Запрос Задачи",
        taskKeyExpression: "Выражение Ключа Задачи",
        taskTitleExpression: "Выражение Заголовка Задачи",
        taskStartDateExpression: "Выражение Даты Начала Задачи",
        taskEndDateName: "Имя Даты Окончания Задачи",
        taskParentExpression: "Выражение Родительской Задачи",
        taskProgressExpression: "Выражение Прогресса Задачи",
        taskColorExpression: "Выражение Цвета Задачи",
        taskConnections: "Связи Задачи",
        diagramClear: "Вы уверены, что хотите очистить диаграмму? Это действие нельзя отменить.",
        uniqueComponentMenuCloneWarning: "Пожалуйста, измените уникальный компонент перед его копированием.",
        filesLoading: "Файлы загружаются...",
        couldnotbeLoaded: "не может быть загружено.",
        fileSizeLimit: "Лимит Размера Файла",
        filesUploaded: "Файлы загружены",
        filesWillDeleted: "Файлы будут удалены!",
        filesSureDelete: "Вы уверены, что хотите удалить файлы?",
        fileDelete: "Вы хотите удалить файл?",
        uploadedPhotos: "Загруженные Фотографии",
        maxSize: "Максимальный Размер",
        center: "Центр",
        size: "Размер",
        canItBeUpdated: "Можно ли обновить после установки?",
        uploadedFiles: "Загруженные Файлы",
        type: "Тип",
        showdeleteOldData: "Параметры Удаления Старых Данных",
        videoBrowser: "Ваш браузер не поддерживает теги видео.",
        deleted: "Удалено",
        theseComponentElementsDeleted: "Элементы этого компонента будут удалены.",
        invalidTarget: "Недопустимая Цель",
        ridge: "Сплошная Линия",
        dotted: "Точечная",
        dashed: "Пунктирная",
        addRowColumn: "Добавить Строку/Столбец",
        dataPagingShow: "Отображение данных на страницах",
        borderType: "Тип Границы",
        maxCharacters: "Максимальное Количество Знаков",
        edgeThickness: "Толщина Грани",
        lineSpacing: "Междустрочный Интервал",
        settingsRow: "Настройки Строки/Столбца",
        addRow: "Добавить Строку",
        deleteRow: "Удалить Строку",
        rowPermission: "Разрешения на Строку",
        addTabs: "Добавить Вкладки",
        deletePanel: "Удалить Панель",
        tabPermission: "Разрешение на Вкладку",
        addPanel: "Добавить Панель",
        panelPermission: "Разрешение на Панель",
        backgroundColor: "Цвет Фона",
        saveAndCloseChildForm: "Сохранить и Закрыть Дочернюю Форму при открытии новой?",
        icon: "Иконка",
        selectForm: "Выберите Форму",
        fontColor: "Цвет Текста",
        minCharacters: "Минимальное Количество Знаков",
        generalCategory: "Общая Категория",
        removeRow: "Вы уверены, что хотите удалить запись?",
        send: "Отправить",
        declineFinish: "Отклонить и Завершить",
        finish: "Завершить",
        noData: "Нет Данных",
        createEditMenu: "Создать/Редактировать Меню",
        addNewBreakdown: "Добавить Новое Разделение",
        addForm: "Добавить Форму",
        addBottomBreak: "Добавить Нижнее Разделение",
        addNode: "Добавить Узел",
        topCategory: "Верхняя Категория",
        explanation: "Пояснение",
        browserTitle: "Заголовок Браузера",
        formTitle: "Заголовок Формы",
        addSqlColumn: "Добавить SQL Столбец",
        formBg: "Фон Формы",
        codeEditor: "Редактор Кода",
        authorityType: "Тип Авторизации",
        viewOnly: "Только Просмотр",
        dark: "Тёмный",
        white: "Белый",
        hightBlack: "Чёрный",
        run: "Запустить",
        rowColor: "Цвет Строки",
        operator: "Оператор",
        flowOfForm: "Поток Формы",
        columnWidth: "Ширина Столбца",
        alignLeft: "Выровнять по Левому Краю",
        alignRight: "Выровнять по Правому Краю",
        alignButton: "Выровнять Кнопку",
        imageTableNameColumn: "Имя Таблицы",
        uploadBg: "Загрузить Фон",
        selectFile: "Выберите Файл",
        dropFile: "Перетащите Файл сюда",
        alwaysRoundUp: "Всегда Округлять Вверх",
        alwaysRoundBot: "Всегда Округлять Вниз",
        default: "По Умолчанию",
        deleteOldDataTable: "Удалить старые данные из таблицы?",
        deleteOldData: "Удалить старые данные?",
        integer: "Целое Число",
        double: "Дробное Число",
        pleaseFillFields: "Пожалуйста, заполните обязательные поля.",
        toastColumnNameNumber: "Имя столбца не может начинаться с числа",
        areYouSure: "Вы уверены?",

        columnDeleted: "Столбец будет удален",
        allColumnDeleted: "Все столбцы будут удалены",
        idCantStartNumber: "Идентификатор не может начинаться с числа",
        uploadFile: "Загрузить Файл",
        fileViewer: "Просмотрщик Файлов",
        ok: "ОК",
        map: "Карта",
        editPanel: "Редактировать Панель",
        filterColumnName: "Имя Столбца Фильтра",
        hintExamples: "СуммаКоличество, пример: Зарплата",
        columnLanguageSettings: "Настройки Языка Столбца",
        fileColumn: "Столбец Файла",
        fileNameColumn: "Столбец Имени Файла",
        extensionColumn: "Расширение столбца",
        multipleSelection: "Множественный выбор",
        addSelectionsRows: "Добавить выбранные строки",
        mapSettings: "Настройки карты",
        formatAndFeatures: "Формат и функции",
        enterDateRange: "Введите диапазон дат",
        filesCanUploadedUsers: "Пользователи могут загружать файлы",
        barcodeColumn: "Столбец штрих-кода",
        autoAscend: "Авто-восход",
        roundType: "Тип округления",
        font: "Шрифт",
        columnProcess: "Обработка столбца",
        exportColumnTotal: "Экспортировать итоговый столбец",
        outsideElementDisabled: "Отключить внешние элементы",
        chat: "Чат",
        languages: "Языки",
        display: "Отображение",
        enterYourComment: "Введите ваш комментарий",
        comment: "Комментарии",
        fileUploaded: "Файл загружен",
        pdfViewIsButton: "Показать как кнопку",
        takeScreenshot: "Сделать снимок экрана",
        alertText: "Текст уведомления",
        clearButtonVisible: "Видимость кнопки 'Очистить'",
        allText: "Все",
        letter: "Только буквы",
        letterandnumber: "Буквы и цифры",
        letterandsymbol: "Буквы и символы",
        numberandsymbol: "Цифры и символы",
        showIDColumn: "Показать столбец идентификатора",
        showCopyButton: "Показать кнопку 'Копировать'",
        showDeleteButton: "Показать кнопку 'Удалить'",
        showCheckboxButton: "Показать кнопку 'Флажок'",
        autoRowHeight: "Авто высота строки",
        exportImport: "Экспорт/Импорт формы",
        export: "Экспорт",
        import: "Импорт",
        toastImport: "Импорт формы завершен.",
        warningApproveImport: "Пожалуйста, подтвердите импорт после настройки. Импорт формы закроет текущую открытую форму.",
        defaultTodaysDateWithTime: "Значение по умолчанию - сегодняшняя дата с временем",
        filterVisible: "Показать фильтр столбца",
        updatetableMaxQuantity: "Максимальное количество обновлений",
        hideQuantity: "Скрыть поле количества",
        disableQuantityTextbox: "Отключить текстовое поле количества",
        multiplesOfQuantity: "Кратные количеству увеличения/уменьшения",
        justnumber: "Только числа",
        paymentHourWarning: "Платежи могут быть произведены только в рабочее время.",
        addColumn: "Добавить столбец",
        mailSettings: "Настройки почты",
        newTaskMailDetail: "Текст почты при назначении новой задачи",
        updateTaskMailDetail: "Текст почты при обновлении задачи",
        taskOnDropMail: "Текст почты при перетаскивании задачи",
        selectSMTP: "Выберите SMTP адрес",
        taskCreator: "Исполнитель задачи",
        taskEndDate: "Дата окончания задачи",
        tags: "Метки",
        taskStartDate: "Дата начала задачи",
        taskAssigned: "Назначенная задача",
        taskDetail: "Детали задачи",
        taskSubject: "Тема задачи",
        taskPri: "Приоритет задачи",
        taskEdit: "Редактирование задачи",
        addTask: "Добавить задачу",
        taskStatus: "Статус задачи",
        excelExportSettings: "Настройки экспорта в Excel",
        excelExportRequiredColor: "Цвет обязательного столбца",
        allDataExcelExport: "Экспорт данных в Excel",
        warningApproveImportReport: "Пожалуйста, подтвердите импорт после настройки. Импорт отчета закроет текущий открытый отчет.",
        reportName: "Имя отчета",
        emptyReportName: "Пожалуйста, добавьте название отчета с правой стороны.",
        old: "Старое",
        upperNode: "Верхний узел",
        pdfViewStaticPdf: "Показать статический PDF",
        actions: "Действия",
        loading: "Загрузка",
        exportDataToExcell: "Экспортировать все данные в Excel",
        showRefreshIcon: "Показать иконку обновления",
        headerFilter: "Фильтр заголовка",
        filterRowVisible: "Видимость строки поиска в столбце",
        password: "Пароль",
        categories: "Категории",
        pressEnter: "Нажмите Enter",
        historyColumnValue: "Значение столбца истории",
        hideMobile: "Скрыть на мобильных устройствах",
        align: "Выравнивание",
        theme: "Тема",
        info: "Информация",
        showTitle: "Показать заголовок",
        purple: "Пурпурный",
        brown: "Коричневый",
        yellow: "Желтый",
        turnOnComments: "Включить комментарии",
        showContent: "Показать содержание",
        canBeHidden: "Может быть скрытым",
        allowEdit: "Разрешить редактирование",
        allowDelete: "Разрешить удаление",
        allowAdd: "Разрешить добавление",
        allowCopy: "Разрешить копирование",
        toastDeleteErrorMessage: "Этот компонент используется в файлах настроек формы. Его нельзя удалить.",
        formOpeningMultiElementEvent: "Событие создания нескольких элементов",
        resetDatagridLocalSettings: "Сброс настроек локального хранилища",
        loginP1: "River - это полноценная система памяти организации или компании, которая может быть использована как источник информации о прошлых опытах, успехах, неудачах, уроках, культуре и ценностях вашей организации для принятия будущих решений и действий. Корпоративная память охватывает историю организации, бизнес-процессы, знания сотрудников, опыт клиентов, развитие продуктов и услуг и многое другое. Эти данные будут служить ориентиром для будущих целей и стратегий организации.",
        loginP2: "River с помощью веб-интерфейса и развитых мобильных приложений помогает более эффективно и эффективно управлять бизнес-процессами вашей компании. Все, что вам нужно, находится в нескольких кликах, ваши компьютер, планшет или смартфон.",
        loginConnect: "Подключиться",
        loginText: "Вход",
        loginRightMore: "Больше",
        loginHeaderP1: "Ваш корпоративный опыт",
        loginHeaderP2: "Мобильность",
        loginHeaderP3: "Графическая мощь",
        kanbanaddTasks: "Добавление задачи в Канбан",
        taskstatusDesciption: "Определяет отображение имени столбца, в который будет назначена задача в Канбане.",
        tasksubjectDescription: "Позволяет вводить заголовок для задачи в Канбане. Заголовок задачи является обязательным полем.",
        taskassignedToTask: "Группы, добавленные к задаче",
        taskassignedTaskDescription: "Позволяет выбирать группы, которые будут назначены задаче в Канбане.",
        addedForTasks: "Добавлено для задачи",
        taskAssignedInfoDescription: "Люди, которых следует уведомить о задаче",
        assignedInfo: "Уведомляемые лица",
        groupInfo: "Группы, которые следует уведомить о задаче",
        sortEnd: "Сортировать по дате завершения",
        sender: "Отправитель",
        forward: "Переслать",
        attachments: "Вложения",
        taskdetailDescription: "Позволяет вводить детали задачи в Канбане. Детали задачи являются обязательным полем.",
        taskassignedDescription: "Позволяет выбирать пользователей, назначенных на задачу в Канбане. В Канбане обязательно должен быть назначен хотя бы один пользователь.",
        taskstartdateDescription: "Позволяет выбирать дату начала задачи в Канбане. В Канбане дата начала автоматически устанавливается во время создания задачи.",
        taskenddateDescription: "Позволяет выбирать дату завершения задачи в Канбане. В Канбане дата завершения задачи автоматически устанавливается на неделю после создания задачи.",
        taskpriDescription: "Позволяет выбирать приоритет задачи в Канбане. В Канбане приоритет задачи автоматически устанавливается на неделю после создания задачи.",
        tagsDescription: "Позволяет добавлять метки к задаче в Канбане. Метки разделяются запятой при добавлении.",
        outlookDescription: "При включенной опции добавления в Outlook при создании задачи пользователь может автоматически добавить задачу в свой Outlook-календарь.",
        taskAssInfoDescription: "Используется для указания людей, не назначенных на задачу, но о которых есть информация. Люди, добавленные для уведомления, не могут выполнять действия с задачей.",
        receiver: "Получатель",
        draft: "Черновик",
        spam: "Спам",
        filterstate: "Фильтр по состоянию",
        checkForspelling: "Проверить орфографию",
        emailToForward: "Пересылаемое письмо",
        lowImpMails: "Письма низкой важности",
        normalImpMails: "Письма обычной важности",
        highImpEmails: "Письма высокой важности",
        showReadMails: "Показать прочитанные письма",
        groupinfoDescription: "Используется для указания групп, не назначенных на задачу, но о которых есть информация. Люди, добавленные для уведомления, не могут выполнять действия с задачей.",
        timeoutMission: "Время предупреждения до окончания задачи",
        timeoutmissionDescription: "Используется для указания времени, за которое начинается предупреждение о близком завершении задачи в Канбане.",
        taskperiodDescription: "Используется для указания периодического напоминания о близком завершении задачи в Канбане.",
        taskProgress: "Прогресс задачи",
        taskProgressDescription: "Используется для указания степени выполнения задачи в Канбане. Прогресс задачи может быть отображен на диаграмме Ганта.",
        updatekanbanTask: "Обновление задачи в Канбане",
        taskcreatorDescription: "Отображает пользователя, который создал задачу в Канбане.",
        uploadfileDescription: "Используется для загрузки файлов к задаче в Канбане. Файлы и изображения сохраняются в системе управления файлами (DMS).",
        infoPersons: "Уведомляемые лица",
        warningtime: "Время предупреждения",
        taskPeriod: "Период задачи",
        taskProgresSatus: "Статус прогресса задачи",
        makeTask: "Создатель задачи",
        addedforTasks: "Добавлено для задачи",
        outlookIntegration: "Интеграция с Outlook",
        loginoutlook: "Войти в Outlook-календарь",
        fetchoutlook: "Получить данные из Outlook-календаря",
        progressStatus: "Статус прогресса",
        taskColors: "Цвета задач",
        addcalendarTask: "Добавить задачу в календарь",
        updatetaskCalendar: "Обновить задачу в календаре",
        statusComplete: "Фильтрация по статусу завершения",
        priorityFilter: "Фильтрация по приоритету",
        columnFilter: "Фильтрация по столбцу",
        assignPerson: "Назначенные лица",
        assignGroup: "Назначенные группы",
        noTask: "Задачи не найдены",
        resetSort: "Сбросить сортировку",
        sortASC: "Сортировать от A до Z",
        sortDESC: "Сортировать от Z до A",
        sortAppointment: "Сортировать по дате назначения",
        trash: "Корзина",
        showOlderMail: "Показать более старые письма",
        mailNotFound: "Письмо не найдено",
        sendNewMail: "Отправить новое письмо",
        showUnreadMails: "Показать непрочитанные письма",
        updateColumnMail: "Сообщение, которое будет отправлено при добавлении задачи в столбец",
        addTaskToOutlook: "Добавить задачу в Outlook",
        enterYourMessage: "Введите ваше сообщение",
        reminder: "Напоминание",
        determinedToTask: "Заданное время для задачи",
        remainingTime: "Оставшееся время",
        hours: "часов",
        withoutPriority: "Без приоритета",
        lowPri: "Низкий приоритет",
        normalPri: "Обычный приоритет",
        highPri: "Высокий приоритет",
        showAllTasks: "Показать все задачи",
        showIncompleteTasks: "Показать незавершенные задачи",
        showCompeletedTasks: "Показать завершенные задачи",
        reportDesigner: "Дизайнер отчетов",
        fileManagementSystem: "Система управления файлами",
        formDesigner: "Дизайнер форм",
        boardDesigner: "Дизайнер панели",
        themeSettings: "Настройки темы",
        social: "Социальное",
        profile: "Профиль",
        contacts: "Контакты",
        files: "Файлы",
        pages: "Страницы",
        noResultsFound: "Результаты не найдены",
        processes: "Процессы",
        filterByStatus: "Фильтровать по статусу",
        filterByPri: "Фильтровать по приоритету",
        filterByCol: "Фильтровать по колонке",
        todoAddTasks: "Добавить задачу в To-Do",
        addLabel: "Введите метку",
        showWithSubTasks: "Показать с подзадачами",
        riverBoard: "Панели River",
        notify: "Уведомления",
        likedYourPost: "понравилась ваша публикация",
        likedYourComment: "понравился ваш комментарий",
        systemNotify: "Системные уведомления",
        readAllNotify: "Прочитать все уведомления",
        recommendedUsers: "Рекомендуемые пользователи",
        suggestedPosts: "Рекомендуемые публикации",
        sPostReposted: "публикация перепостнута",
        shareSomething: "Поделитесь чем-то",
        updatePost: "Обновить публикацию",
        ifYouDoNotSelectGroup: "Если вы не выберете группу, вашу публикацию увидят все",
        addComment: "Добавить комментарий",
        sendComment: "Отправить комментарий",
        areYouSureYouWantToDeleteThisPost: "Вы уверены, что хотите удалить эту публикацию",
        yes: "Да",
        no: "Нет",
        deletePost: "Удалить публикацию",
        postSuccesfullyDeleted: "Публикация успешно удалена",
        deleteComment: "Удалить комментарий",
        areYouSureYouWantToDeleteThisComment: "Вы уверены, что хотите удалить этот комментарий",
        commentSuccesfullyDeleted: "Комментарий успешно удален",
        sharePost: "Поделиться публикацией",
        areYouSureYouWantToShareThisPost: "Вы уверены, что хотите поделиться этой публикацией",
        repostedSuccessfully: "Успешно перепостнуто",
        upload: "Загрузить",
        refresh: "Обновить",
        allowedExtentions: "Разрешенные расширения: JPG, GIF или PNG. Максимальный размер файла",
        systemLandingPagePreference: "Предпочтительная страница системы",
        allownonadminuserstosearchforotherusers: "Разрешить пользователям без административных прав искать других пользователей",
        passwordChange: "Смена пароля",
        newPassword: "Новый пароль",
        retypethenewpassword: "Повторите новый пароль",
        notifications: "Уведомления",
        showWhoLikesYourPost: "Показывать, кто понравилась ваша публикация",
        showWhoLikesYourComment: "Показывать, кто понравился ваш комментарий",
        aboutMe: "Обо мне",
        bio: "Биография",
        dateOfBirth: "Дата рождения",
        website: "Веб-сайт",
        phone: "Телефон",
        saveChanges: "Сохранить изменения",
        username: "Имя пользователя",
        content: "Содержание",
        projectManagement: "Управление проектами",
        addPage: "Добавить страницу",
        addFolder: "Добавить папку",
        showUsers: "Показывать пользователям",
        showGroups: "Показывать группам",
        readonlyUsers: "Только для чтения пользователям",
        readonlyGroups: "Только для чтения группам",
        columnHeight: "Высота колонки",
        showKanbanCount: "Показывать количество задач в Kanban",
        kanbanSettings: "Настройки Kanban",
        gantSettings: "Настройки Gantt",
        schedulerSettings: "Настройки календаря",
        showRowLines: "Показывать линии строк",
        activateOutlookConnection: "Активировать подключение к Outlook",
        selectOutlookColumn: "Выберите колонку Outlook",
        selectSMTPPopoverText: "SMTP выбирается для использования в компонентах Kanban, Gantt, Calendar или To-Do. ",
        menuFilePathId: "ID папки меню River",
        recycleBinPathName: "Имя папки корзины River",
        recycleBinPathId: "ID папки корзины River",
        fileNameChangedMessage: "Имя файла успешно изменено.",
        fileNameChangedErrorMessage: "Имя файла не может быть изменено.",
        fileCreatedMessage: "Файл успешно создан.",
        newTaskMailDetailPopoverText: "Этот раздел используется для добавления содержания автоматического письма, которое будет отправлено назначенным пользователям, когда новая задача добавляется в компоненты Kanban, Gantt, Calendar или To-Do. ",
        taskOnDropMailPopoverText: "Этот раздел используется для добавления содержания автоматического письма, которое будет отправлено назначенным пользователям, когда задача перемещается в другую колонку в компонентах Kanban, Gantt, Calendar или To-Do. ",
        createdBy: "Создано",
        updatedBy: "Обновлено",
        createdDate: "Дата создания",
        updatedDate: "Дата обновления",
        folderPermissionSettings: "Настройки разрешений для папок",
        quantity: "Количество",
        fileManagementPermissionSettings: "Настройки разрешений для управления файлами",
        fileNotFoundErrorText: "Файлы, соответствующие вашим критериям, не найдены.",
        updateTaskMailDetailPopoverText: "Этот раздел используется для добавления содержания автоматического письма, которое будет отправлено назначенным пользователям, когда в задаче в компонентах Kanban, Gantt, Calendar или To-Do происходят изменения. ",
        selectOutlookColumnPopoverText: "Здесь выбирается колонка, в которую будут добавляться задачи из Outlook. ",
        createFolder: "Создать папку",
        versionHistory: "История версий",
        deletePermanently: "Удалить навсегда",
        recover: "Восстановить",
        groupPermissions: "Права группы",
        allowUpload: "Разрешить загрузку файлов",
        allowView: "Разрешить просмотр файлов",
        allowDownload: "Разрешить скачивание файлов",
        permissionType: "Тип разрешения",
        applyToSubFolders: "Применить ко всем подпапкам",
        newFolderName: "Новое имя папки",
        tagTips: "Теги начинаются с символа # и могут содержать только цифры и буквы",
        addAsNewVersion: "Добавить как новую версию",
        addAndChangeName: "Добавить и изменить имя",
        skip: "Пропустить",
        fileExistError: "Файл с таким именем уже существует в целевой папке",
        processFileShow: "Показать папку процесса River",
        processFilePathName: "Имя папки процесса River",
        processFilePathId: "Идентификатор папки процесса River",
        menuFileShow: "Показать папку меню River",
        menuFilePathName: "Имя папки меню River",
        fileCreatedErrorMessage: "Ошибка создания файла",
        fileDeletedessage: "Файл успешно удален",
        fileDeletedErrorMessage: "Ошибка удаления файла",
        fileMovedMessage: "Файл успешно перемещен",
        fileMovedErrorMessage: "Ошибка перемещения файла",
        fileCopyMessage: "Файл успешно скопирован",
        fileCopyErrorMessage: "Ошибка копирования файла",
        fileDownloadMessage: "Файл успешно скачан",
        fileDownloadErrorMessage: "Ошибка скачивания файла",
        fileSizeShowErrorMessage: "Файл слишком большой для предварительного просмотра. Пожалуйста, скачайте файл.",
        unsupportedFile: "Неподдерживаемый файл",
        fileNameError: "Файл с таким именем уже существует. Пожалуйста, попробуйте другое имя.",
        fileGivePermissionMessage: "Разрешения на файл успешно установлены",
        fileGivePermissionErrorMessage: "Ошибка установки разрешений на файл. Запись не удалась.",
        groupName: "Имя группы",
        createGroup: "Создать группу",
        conversation: "Диалоги",
        startConversation: "Начать диалог",
        userNotFound: "Пользователь не найден",
        chatNotFound: "Чат не найден",
        themeTypeLight: "Светлая",
        themeTypeBorderedLight: "Светлая с границей",
        themeHalfDark: "Полутемная",
        themeDark: "Темная",
        themeRouteAnimation: "Анимация маршрута",
        themeRouteAnimationFadeInLeft: "Появление слева",
        themeRouteAnimationZoomIn: "Увеличение",
        themeRouteAnimationFadeIn: "Плавное появление",
        themeRouteAnimationNone: "Отсутствует",
        themeMenuType: "Тип меню",
        themeMenuTypeHorizontal: "Горизонтальное",
        themeMenuTypeVertical: "Вертикальное",
        themeMenuCollapse: "Свернутое меню",
        themeMenuHidden: "Скрытое меню",
        themeNavbarColor: "Цвет навигационной панели",
        themeNavbarType: "Тип навигационной панели",
        themeSticky: "Закрепленная",
        themeStatic: "Статичная",
        themeFloating: "Плавающая",
        themeHidden: "Скрытая",
        themeFooterType: "Тип нижнего колонтитула",
        catalog: "Каталог",
        riverBoards: "Панели River",
        clipboardDesigner: "Дизайнер буфера обмена",
        desingMode: "Режим дизайна",
        editPage: "Редактировать страницу",
        rename: "Переименовать",
        exportToPdf: "Экспортировать в PDF",
        exportToExcel: "Экспортировать в Excel",
        revenueReport: "Отчет о доходах",
        header: "Заголовок",
        connection: "Подключение",
        primaryKey: "Первичный ключ",
        secondaryKey: "Вторичный ключ",
        completedKey: "Ключ завершения",
        countKey: "Ключ подсчета",
        statusKey: "Ключ статуса",
        progressConnection: "Подключение к процессу",
        progressScript: "Скрипт выполнения",
        progressPrimaryKey: "Первичный ключ выполнения",
        progressCountKey: "Ключ подсчета выполнения",
        progressPercentageKey: "Ключ процента выполнения",
        progressColorKey: "Ключ цвета выполнения",
        keyColumn: "Ключевая колонка",
        currency: "Валюта",
        whitePage: "Белая страница",
        noReportSelected: "Отчет не выбран",
        noTypeSelected: "Тип отчета не выбран",
        whitePageReports: "Отчеты на белой странице",
        addBranch: "Добавить кривую/подкривую",
        branchName: "Имя кривой",
        rowNumber: "Номер строки",
        reportTypeSelect: "Выберите тип отчета",
        reportDescription: "Описание отчета...",
        useWeb: "Использовать на веб-сайте",
        useMobile: "Использовать на мобильном устройстве",
        mainScript: "Главный скрипт",
        prerunScript: "Запустить предварительный скрипт?",
        preScript: "Предварительный скрипт",
        testIt: "Проверить",
        selectAll: "Выбрать все",
        addReport: "Добавить отчет",
        selectReportType: "Выберите тип отчета",
        reportType: "Тип отчета",
        reportDesingPermission: "У пользователя нет разрешения на дизайн отчетов.",
        reportImportedOpenLeftMenu: "Отчет импортирован. Вы можете открыть его в левом меню.",
        forReportExport: "Для экспорта отчета сначала выберите отчет.",
        anErrorOccurred: "Произошла ошибка",
        reportDeleted: "Отчет удален",
        branchDeleted: "Кривая удалена",
        addBranchName: "Введите имя кривой, пожалуйста",
        selectConnectionAddress: "Выберите адрес соединения",
        enterSqlScript: "Введите SQL-скрипт",
        enterReportName: "Введите имя отчета",
        reportAdded: "Отчет добавлен",
        numberOfDataToBeProcessed: "Установлено успешное соединение. Количество данных для обработки:",
        dataNotFoundCheckYourConnection: "Данные не найдены! Пожалуйста, проверьте ваше соединение и SQL-команду.",
        connectionNotFoundCheckYourConnection: "Соединение не установлено! Пожалуйста, проверьте ваше соединение и SQL-команду.",
        fillConnectionAndSql: "Пожалуйста, заполните соединение и SQL-команду.",
        allTypesActivate: "Активировать все типы при отображении",
        passwordRequired: "Пароль обязателен",
        showLess: "Показать меньше",
        showMore: "Показать больше",
        authority: "Полномочие",
        preScriptDescription: "Описание предварительного сценария",
        enterReportFilters: "Введите фильтры отчета",
        gridDesigner: "Дизайнер сетки",
        addMainBranch: "Добавить основное подразделение",
        resetSettings: "Сбросить настройки",
        columsToGroup: "Используйте контекстное меню заголовка столбца для группировки данных",
        customize: "Настроить",
        colorType: "Тип цвета",
        formatManagement: "Управление форматом",
        bottomTotalColumn: "Нижний итоговый столбец",
        formatType: "Тип формата",
        datagridSettingsReset: "Сброс настроек сетки данных",
        unexpectedError: "Извините, произошла неожиданная ошибка.",
        changesSaved: "Изменения сохранены",
        addGantTask: "Добавить задачу Ганта",
        updGantTask: "Обновить задачу Ганта",
        updSchedulerTask: "Обновить задачу планировщика",
        updTodoTask: "Обновить задачу To-Do",
        completeTask: "Завершить задачу",
        cantBeEmptyKanban: "Заголовок задачи, детали задачи и назначенный пользователь не могут быть пустыми.",
        successAdd: "Задача успешно добавлена",
        successUpd: "Задача успешно обновлена",
        statusChangeTodo: "Статус задачи успешно изменен на выполненный.",
        reminderSended: "Успешно отправлено напоминание о задаче по почте",
        askForCompleteTask: "Вы уверены, что хотите завершить задачу?",
        infoForCompletedTask: "Все подзадачи также будут завершены.",
        noPermisison: "У вас нет разрешения на это.",
        successDependencyAdded: "Зависимость задач успешно добавлена",
        filterimportant: "Фильтровать по важности",
        enterEmail: "Пожалуйста, введите адрес электронной почты",
        reply: "Ответить",
        newCol: "Новый столбец",
        thickness: "Толщина",
        distanceToUpperComponent: "Расстояние до верхнего компонента",
        companyName: "Название компании",
        showEmailModule: "Показать модуль электронной почты",
        distanceToBottomComponent: "Расстояние до нижнего компонента",
        canNonAdminUsersPost: "Разрешить обычным пользователям размещать сообщения?",
        columnColor: "Цвет столбца",
        byWho: "От кого",
        updated: "Обновлено",
        commentAdded: "Комментарий добавлен",
        completed: "Завершено",
        addRemoveColumns: "Добавить/Удалить столбцы",
        searchMessage: "Поиск в сообщениях",
        riverReports: "Отчеты River BI",
        forms: "Формы",
        searchinProcess: "поиск в процессах",
        searchVoiceMessage: "Ваш поиск в этом голосовом сообщении",
        searchVoiceMessages: "Поиск голосовых сообщений",
        showSocialNotifications: "Показать уведомления из социальных сетей",
        showProjectManagementNotifications: "Показать уведомления управления проектом",
        showProcessNotifications: "Показать уведомления о процессах",
        pageDesingPermission: "У пользователя нет разрешения на дизайн страницы.",
        filemanagementDesingPermission: "У пользователя нет разрешения на управление файлами.",
        taskDelete: "Удалить задачу.",
        tasksWillDeleted: "Вы уверены, что хотите удалить эту задачу?",
        componentSettings: "Настройки компонента",
        aboutComponent: "О компоненте",
        sureSaveChanges: "Вы уверены, что хотите сохранить внесенные изменения?",
        showDeletedNotifications: "Показать удаленные уведомления",
        shareSometthing: "Поделиться чем-то",
        resetPageLayout: "Сбросить макет страницы",
        downloadingSS: "Идет загрузка скриншота. Пожалуйста, подождите.",
        showHorizontalLines: "Показывать горизонтальные линии",
        turnOnVerticalHide: "Включить вертикальное скрытие",
        whichHorizontalLinelinesAreNotVisible: "Какие горизонтальные линии следует скрыть",
        priority: "Приоритет",
        boardSidebarMessageField: "Поле сообщения задачи",
        boardSidebarExtraField: "Поле дополнительных компонентов задачи",
        boardSidebarTaskHistory: "История задачи",
        loadMoreNotifications: "Загрузить больше уведомлений",
        deletedNotifications: "Удаленные уведомления",
        noFormNotification: "Нет уведомлений о процессах",
        noDeletedFormNotifications: "Нет удаленных уведомлений о процессах",
        noDeletedBoardNotification: "Нет удаленных уведомлений о панели",
        noBoardNotification: "Нет уведомлений о панели",
        noDeletedSocialNotification: "Нет удаленных уведомлений из социальных сетей",
        noSocialNotification: "Нет уведомлений из социальных сетей",
        deleteAll: "Удалить все",
        markAllRead: "Пометить все как прочитанные",
        board: "Панель",
        form: "Форма",
        formNotifications: "Уведомления о форме",
        boardNotifications: "Уведомления о панели",
        socialNotifications: "Социальные уведомления",

        email: "Электронная почта",
        oldFormName: "Старое имя формы",
        newFormName: "Новое имя формы",
        oldFormVariable: "Старая переменная формы",
        newFormVariable: "Новая переменная формы",
        oldCategory: "Старая категория",
        newCategory: "Новая категория",
        connectionInFile: "Соединение в файле",
        yourConnection: "Ваше соединение",
        element: "Элемент",
        script: "Скрипт",
        formClosingEvent: "Событие закрытия формы",
        message: "Сообщение",
        formExportTextError: "Форма не содержит компонентов или не была сохранена. Пожалуйста, исправьте это, чтобы избежать ошибок.",
        checkRequiedFields: "Проверить обязательные поля",
        dontCheckRequiredFields: "Не проверять обязательные поля",
        createPdfWarning: "При этой опции форма не будет сохранена и закрыта",
        cratePdf: "Создать PDF",
        identityNumber: "Идентификационный номер",
        minPaymentHour: "Минимальный час",
        maxPaymentHour: "Максимальный час",
        darkBlue: "Тёмно-синий",
        orange: "Оранжевый",
        component: "Компонент",
        properties: "Свойства",

        dmsPath: "Путь DMS",
        dashboard: "Панель управления",
        slider: "Слайдер",
        list: "Список",
        otherImages: "Другие изображения",
        unitScript: "Скрипт блока",
        updatableMaxQuantity: "Максимальное количество для обновления",
        newPage: "Новая страница",
        code: "Код",
        resetHiddenComponent: "Сбросить значение скрытых компонентов",
        bold: "Полужирный",
        italic: "Курсив",
        stringFormat: "Формат строки",
        videoUploaded: "Видео загружено",
        fillInAllFields: "Заполните все поля",
        normalPaging: "Обычная постраничная навигация",
        pageSize: "Размер страницы",
        special: "Специальный",
        link: "Ссылка",
        fontWeight: "Жирный шрифт",
        pdfFileNotFound: "PDF файл не найден",
        likedOn: "понравилось",
        addedTask: "добавил задачу",
        updatedTask: "обновил задачу",
        messageAddedToTask: "добавил сообщение к задаче",
        deleteTask: "удалил задачу",
        completedTask: "завершил задачу",
        subCategory: "Подкатегория",
        toTheBoard: "На доску",
        changeTasksPage: "Сменить страницу задач",
        hideTaskDetail: "Скрыть детали задачи",
        selectData: "Выберите данные",
        noContentYet: "Пока нет контента",
        addRowBetween: "Добавить строку между",
        userPermissions: "Права пользователя",
        askForPassword: "Запросить пароль",
        passwordIsWrong: "Пароль неверный, попробуйте еще раз.",
        pleaseEnterPassword: "Пожалуйста, введите пароль пользователя, чтобы продолжить.",
        onePaymentEachSave: "Одно платежное событие для каждого сохранения",
        riverModules: "Модули RIVER",
        processHistory: "История процесса",
        commentHasBeenMadeAtThisStage: "Комментарий был добавлен на этой стадии",
        clearSelection: "Очистить выбор",
        allowDownloadVersionHistoryDocuments: "Разрешить загрузку документов из истории версий",

        delegated: "Делегировано",
        openDocxToPdf: "Открыть файл DOCX в формате PDF",
        activationStartDate: "Дата начала активации",
        activationEndDate: "Дата окончания активации",
        changeActivationDate: "Изменить дату активации",
        showArchive: "Показать архив",
        hideArchive: "Скрыть архив",
        jobListMessageArea: "Область сообщений списка задач",
        generateLink: "Создать ссылку",
        fileLink: "Ссылка на файл",
        givePasswordToTheLink: "Установить пароль для ссылки",
        giveTheLinkAnExpirationDate: "Установить дату истечения срока действия ссылки",
        tasks: "Задачи",
        maxFileCountLimit: "Максимальное количество файлов",
        fileCountLimit: "Лимит количества файлов",
        numberOfFilesExceeded: "Превышено количество файлов",
        numberOfFilesLimit: "Лимит количества файлов",
        lineIntermediateAllowAdd: "Добавить промежуточную строку",
        lineIntermediateAllowCopy: "Копировать промежуточную строку",
        triggerReport: "Триггерный отчет",
        setWhitePageReportExportName: "установить имя экспорта отчета на белой странице",
        menuRowUpdated: "Есть записи с автоувеличением значения. Автоувеличение номера было обновлено: ",
        isMenuRowControl: "Управление автоинкрементом",
        sqlScriptWarning: "Использование этого поля может вызвать потерю производительности.",


        deletedTask: "Удаленная задача",
        getDeletedTasks: "Получить удаленные задачи",
        getTasks: "Получить задачи",
        movePageDesignersNode: "Выбранный дизайн панели/меню будет перемещен в папку",
        informationAboutMoveToDirectory: "Права пользователя на перемещенной панели/меню изменятся в соответствии с правами папки, в которую она перемещена.",
        move: "Переместить",
        download: "Скачать",
        create: "Создать",
        statistic: "Статистика",
        priRate: "Уровень назначения по приоритету",
        taskcreatorlist: "Список создателей задач",
        completionRate: "Уровень завершения",
        columnsTaskCount: "Количество задач в колонках",
        showKanbanFilter: "Показать фильтры Канбана",
        processToStart: "Процесс для запуска",
        fieldMatching: "Сопоставление полей",
        showFileHistory: "Показать историю файла",
        dontLetDeleteWhatTheyAdded: "Они могут удалить то, что добавили",
        myJobs: "Мои работы",
        excelExportName:"имя экспорта",
        filterSearch: "Поиск в задачах",
        filterForAssignUsers: "Фильтр по исполнителю",
        filterForAssignUGroups: "Фильтр по группе",
        filterForPri: "Фильтр по приоритету",
        clearFilter: "Очистить фильтры",
        makeRequiredField: "Сделать поле обязательным",
        makeReadOnly: "Сделать только для чтения",
        letItWorkProcessContinues: "Пусть это работает, когда процесс продолжается",
        event: "Событие",
        selectEvent: "Выберите событие",
        rowEditor: "Редактор строки",
        formOpeningComponentEvent: "Событие открытия формы для компонента",
        boardToastNotification: "{{0}}  {{3}}  удалил задачу {{2}}   из таблицы  {{1}} ",
        yesterday: "Вчера",
        today: "Сегодня",
        notSaved: "Не сохранено",
        editors: "Редакторы",
        basic: "Основной",
        file: "Файл",
        datagridPreferences: "Настройки сетки данных",
        deleteLetsAsk: "Разрешить удаление?",
        addNewRow: "Без вопросов добавить как новую строку",
        oldRowShouldBeDeleted: "Без вопросов удалить старые строки",
        canBeSearched: "Может быть найдено?",
        processName: "Имя процесса",
        removeReadOnly: "Удалить свойство «Только для чтения»",
        dontMakeRequired: "Не делать обязательным поле",
        dataName: "Имя данных",
        margin: "Поле",
        rowCount: "Количество строк",
        colCount: "Количество столбцов",
        outsideMargin: "Внешнее поле",
        widgetName: "Имя виджета",
        searchInProcess: "Формы процесса",

        changeEventScriptRun: "Должны ли сценарии изменений срабатывать, когда данные заполняются?",
        dontChanceId: "(Этот идентификатор объекта нельзя изменить, потому что он используется в действиях формы компонента.)",
        scriptButtonWarning: "Настройки состояния кнопок сценария не поддерживаются в формах меню.",
        description2: "заявление 2",
         showJobListTabs: "Показывать фотографии пользователей в списке вакансий",
        showUserPhotosInJobList: "показывать фотографии пользователей в списке работ",
        dmsVideoGaleryTabWarning: "У вас нет разрешения на использование этого поля.",
        searchInFormJson: "Поиск в форме",
        useExpandedJoblist: "Использовать расширенный список вакансий",
        editTour: "Редактировать тур",
        selectComponent: "Выберите компонент",
        tourComponents: "Компоненты тура",
        addTourElement: "Добавить элемент тура",
        startTourWhenFormOpen: "Начать тур, когда форма открыта",
        compare: "Сравнить",
        deleteTourElement: "Удалить элемент тура",
        tourWillDeleted: "Тур будет удален",
        tourDelete: "Удалить тур",
        fixedRowHeight: "Фиксированная высота строки",
        pushWidgetItems: "Передвигать элементы виджета",
        allowMultiLayer: "Разрешить несколько слоев",
        gridType: "Тип сетки",

        useCollapsedJoblist: "использовать свернутый список вакансий",
        searchAllData: "Искать все данные",
        scriptsRunAllAreSelected: "Должны ли скрипты запускаться, когда все выбраны?",
        
        menuSchedulerPermission: "У вас нет разрешения на добавление меню",
        showOnTask: "Должен ли редактор появляться в процессах",
        alreadyHaveMenu: "Компонент меню уже есть",
        select: "Выбирать",
        startedUserName: "Имя начального пользователя",
        startedUserId: "Идентификатор запущенного пользователя",
        processInstanceId: "Идентификатор экземпляра процесса",
        formId: "Идентификатор формы",
        min: "минимум",
        labelNotFound:"Метка не определена на этом языке",
    }

}
